import React from "react";

const OrgSettingsDeleteOrgModal = (props) => {

    const {
        closeDeleteOrgModal,
        constants,
        onDeleteOrgClick
    } = props;

    return (
        <div className="org-settings-delete-org-modal__container">
            <div className="org-settings-delete-org-modal">
                <div className="org-settings-delete-org-modal__title">
                    <h3 className="org-settings-delete-org-modal__title-name">{constants.ORG_SETTINGS.DELETE_ORG_MODAL_MESSAGE}</h3>
                </div>
                <div className="org-settings-delete-org-modal__buttons">
                    <button onClick={onDeleteOrgClick} className="org-settings-delete-org-modal__dont-save-btn">{constants.ORG_SETTINGS.DELETE_ORG_MODAL_DELETE_BTN}</button>
                    <button onClick={closeDeleteOrgModal} className="org-settings-delete-org-modal__dont-save-btn">{constants.ORG_SETTINGS.DELETE_ORG_MODAL_CANCEL_BTN}</button>
                </div>
            </div>
        </div>
    )
}
export default OrgSettingsDeleteOrgModal;