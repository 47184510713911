import React from "react";

const StatisticsOrgSettings = (props) => {

    const {
        constants,
        isOrganizationStatistics
    } = props;

    return (
        <div className="statistics-org-settings">
            <h2 className="statistics-org-settings__heading">{constants.ORG_SETTINGS.STATISTICS_ORG}</h2>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_COUNT_EVENT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventCount}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_COUNT_EVENT}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventCount}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE_OPEN}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE_CLOSE}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByType?.open}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByType?.secret}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE}</div>
                        <div className="statistics-org-settings-table-row-mobile">
                            <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE_OPEN}</div>
                            <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByType?.open}</div>
                        </div>
                        <div className="statistics-org-settings-table-row-mobile">
                            <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_TYPE_VOTE_CLOSE}</div>
                            <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByType?.secret}</div>
                        </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_NONE}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_50}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_50_1}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_2_3}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q0}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q50}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q51}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q66}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_NONE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q0}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_50}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q50}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_50_1}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q51}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUORUM_CONDITION_2_3}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventsByQuorum?.q66}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG_ALLOWED}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG_FORBIDDEN}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reRegistration?.allowed}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reRegistration?.prohibited}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG_ALLOWED}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reRegistration?.allowed}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CANCEL_REG_FORBIDDEN}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reRegistration?.prohibited}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_ALLOWED}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_FORBIDDEN}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reVote?.allowed}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reVote?.prohibited}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_ALLOWED}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reVote?.allowed}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBLE_CHANGE_VOICE_FORBIDDEN}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.reVote?.prohibited}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_ALIGNED}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_INTERSECT}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_NOT_INTERSECT}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.combined}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.intersect}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.separated}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_ALIGNED}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.combined}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_INTERSECT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.intersect}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_SYNC_TIME_INTERVALS_NOT_INTERSECT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.timeIntervalType?.separated}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK_ALIGNED}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK_FORBIDDEN}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.joinLink?.allowed}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.joinLink?.prohibited}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK_ALIGNED}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.joinLink?.allowed}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_POSSIBILITY_JOIN_LINK_FORBIDDEN}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.joinLink?.prohibited}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_NOT}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionMaterials?.yes}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionMaterials?.no}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionMaterials?.yes}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_MATERIALS_NOT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionMaterials?.no}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_NOT}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventMaterials?.yes}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventMaterials?.no}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventMaterials?.yes}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_AVAILABILITY_VOTING_QUESTIONS_NOT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.eventMaterials?.no}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_YNQ}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_NONE}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_SINGLE}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_MULTIPLE}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_GRID}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_RADIO_GRID}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.ynq}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.none}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.positionSingle}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.positionMultiple}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.samePosition}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.grid}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.radioGrid}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_YNQ}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.ynq}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_NONE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.none}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_SINGLE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.positionSingle}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION_MULTIPLE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.positionMultiple}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_POSITION}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.samePosition}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_GRID}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.grid}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_QUESTIONS_BY_TYPE_RADIO_GRID}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.questionsByType?.radioGrid}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_NUMBER_OF_MATERIALS_TO_VOTE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.totalQuestionMaterials}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_NUMBER_OF_MATERIALS_TO_VOTE}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.totalQuestionMaterials}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_NUMBER_OF_MATERIALS_FOR_QUESTIONS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.totalEventMaterials}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_NUMBER_OF_MATERIALS_FOR_QUESTIONS}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.totalEventMaterials}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_REG_TIME}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageRegistrationTime}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_REG_TIME}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageRegistrationTime}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_VOTE_TIME}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageVoteTime}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_VOTE_TIME}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageVoteTime}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS_NOT}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.yes}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.no}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.yes}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_OBSERVERS_NOT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.no}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title"></div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS_NOT}</div>
                    </div>
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.yes}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.no}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-body-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS}</div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS_YES}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.yes}</div>
                    </div>
                    <div className="statistics-org-settings-table-row-mobile">
                        <div className="statistics-org-settings-table-cell">{constants.ORG_SETTINGS.STATISTIC_ORG_PRESENCE_OF_COUNTERS_NOT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.withObservers?.no}</div>
                    </div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_TURNOUT}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageTurnout}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_TURNOUT}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageTurnout}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
            <div className="statistics-org-settings-table">
                <div className="statistics-org-settings-table-body-one-row">
                    <div className="statistics-org-settings-table-row">
                        <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_NUMBER_ADD_TO_VOTE}</div>
                        <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageVoters}</div>
                    </div>
                </div>
                {/*-------------------Для мобильной версии-----------------------------------------------------------*/}
                <div className="statistics-org-settings-table-one-row-mobile">
                    <div className="statistics-org-settings-table-cell-title">{constants.ORG_SETTINGS.STATISTIC_ORG_AVERAGE_NUMBER_ADD_TO_VOTE}</div>
                    <div className="statistics-org-settings-table-cell">{isOrganizationStatistics?.averageVoters}</div>
                </div>
                {/*--------------------------------------------------------------------------------------------------*/}
            </div>
        </div>
    )
}
export default StatisticsOrgSettings;