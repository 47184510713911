import React from "react";
import DetailsVoteQuestionsCard from "../DetailsVoteQuestionsCard/DetailsVoteQuestionsCard";

const DetailsVoteQuestions = (props) => {

    const {
        constants,
        currentEventData
    } = props;

    return (
        <div className="details-vote-questions__container">
            <h3 className="details-vote-questions__title-mobile">{constants.DETAILS_VOTE.QUESTIONS_TITLE_MOBILE}</h3>
            <h3 className="details-vote-questions__title">{constants.DETAILS_VOTE.QUESTIONS_TITLE}</h3>
            {
                currentEventData?.questions.map((el) => {
                    return (
                        <DetailsVoteQuestionsCard
                            key={el?.id}
                            nameQuestion={el?.title}
                            rows={el?.options?.rows}
                            columns={el?.options?.columns}
                            typeCard={el?.template}
                            materials={el?.materials}
                            rulesQuestion={el?.rules}
                        />
                    )
                })}
        </div>
    )
}
export default DetailsVoteQuestions;