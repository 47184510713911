import qrcode from "qrcode-generator";
import "./Qr.css";

export const Qr = ({ addData = "" }) => {
  const qr = qrcode(0, "L");
  qrcode.stringToBytes = qrcode.stringToBytesFuncs["UTF-8"];

  qr.addData(addData);

  qr.make();

  return (
    <div
      className="qr"
      dangerouslySetInnerHTML={{ __html: qr.createImgTag(6)}}
    ></div>
  );
};