import * as Template from "../../Api/Template";

export const createNewTemplate = (pathname, requestHelper, body, runUpdateDataEvents, navigate, setErrorMessage, constants, setLoading) => {

        requestHelper(Template.addNewTemplate, body)
            .then((data) => {
                if (data.status === 'ok') {
                    runUpdateDataEvents();
                    navigate('/list-templates');
                    window.scrollTo(0, 0);
                } else {
                    console.log(data);
                    if (data.text === "exceptions.EventsLimitException: Events limit exceeded for org ") {
                        setErrorMessage(constants.ADD_NEW_VOTE.EVENT_LIMIT_ERR);
                        setLoading('');
                    } else {
                        setErrorMessage(constants.ADD_NEW_VOTE.SOMETHING_WENT_WRONG_ERR);
                        setLoading('');
                    }
                }
            })
            .catch((err) => {
                setLoading('');
                throw new Error(err.message);
            })
            .finally(() => {
                runUpdateDataEvents();
                setLoading('');
            })
}