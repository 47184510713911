import React, { useEffect, useState } from "react";
import * as Organizations from '../../Api/Organizations';
import deleteIcon from "../../img/delete-icon.svg";
import {setCorrectNameProtocol} from "../../utils/SetCorrectNameProtocol/setCorrectNameProtocol";

const ProtocolSettings = (props) => {

    const {
        constants,
        requestHelper,
        org,
        reloadOrgPage,
        isOrgSuperAdmin
    } = props;

    const [saveButtonText, setSaveButtonText] = useState(constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE);
    const [isCheckboxActive, setCheckboxActive] = useState(false);
    const [isFileSelected, setFileSelected] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState(constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE);
    const [templateLink, setTemplateLink] = useState('');
    const [newProtocol, setNewProtocol] = useState({});
    const [isSaveButtonActive, setSaveButtonActive] = useState(false);
    const [currentProtocolName, setCurrentProtocolName] = useState('');
    const [isProtocolList, setProtocolList] = useState([]);
    const [isDeleteActiveProtocol, setDeleteActiveProtocol] = useState(false);
    const [isAddNewProtocol, setAddNewProtocol] = useState(false);

    function downloadFile(url, fileName) {
        const req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        const __fileName = fileName;
        req.onload = function (event) {
            const blob = req.response;
            const contentType = req.getResponseHeader("content-type");
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], { type: contentType }), fileName);
            } else {
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link); //remove the link when done
            }
        };
        setTemplateLink('');
        setCurrentProtocolName('');
        req.send();
    }

    useEffect(() => {
        let isMounted = true;
            isMounted && setCheckboxActive(org?.config?.protocol?.enabled);
            isMounted && setProtocolList(org?.config?.protocol?.org_template_links?.map(function (item) {
                return {name: item}
            }))
        return () => {
            isMounted = false;
        }
    }, [org?.config?.protocol?.enabled, org?.config?.protocol?.org_template_links])

    useEffect(() => {
        let isMounted = true;
        if (isDeleteActiveProtocol === true || isAddNewProtocol === true) {
            isMounted && setSaveButtonActive(true);
        } else if (isCheckboxActive !== org.config.protocol.enabled) {
            isMounted && setSaveButtonActive(true);
        } else {
            isMounted && setSaveButtonActive(false);
        }
        return () => {
            isMounted = false;
        }
    }, [isCheckboxActive, isAddNewProtocol, isDeleteActiveProtocol, org?.config?.protocol?.enabled])

    function handleChangeProtocolEnable() {
        if (isCheckboxActive) {
            setCheckboxActive(false);
        } else {
            setCheckboxActive(true);
        }
    }

    function onSelectFileHandler(e) {
        const files = e.target.files;
        const file = files[0];
        const fileName = file.name.replace(/ /g, '--');
        const random = new Date().getTime();
        const fileNameForSave = `${random + '_' + fileName}`;
        setSelectedFileName(file.name);
        setFileSelected(true);
        const data = new FormData();
        data.append(file.name, file, fileNameForSave);
        setNewProtocol(data);
        setAddNewProtocol(true)
    }

    function onHandleDeleteProtocol(i) {
        const removeProtocolList = isProtocolList.filter((el, index) => index !== i)
        setProtocolList(removeProtocolList)
        setDeleteActiveProtocol(true)
    }

    function addNewProtocol() {
        if (isProtocolList === undefined || isProtocolList?.length === 0) {
            setSaveButtonActive(false)
        } else {
            setSaveButtonText(constants.ORG_SETTINGS.BUTTON_LOADING);
        const filteredProtocolName = isProtocolList?.map(el => el?.name)
        const uploadProtocolData = {
            data: newProtocol
        }
        requestHelper(Organizations.uploadProtocol, uploadProtocolData)
            .then((res) => {
                const body = {
                    protocol: {
                        enabled: isCheckboxActive,
                        org_template_links: [...filteredProtocolName, res[0]]
                    },
                    org_id: org?.id
                }
                requestHelper(Organizations.updateProtocolSettings, body)
                    .then(() => {
                        reloadOrgPage();
                        setFileSelected(false);
                        setSaveButtonActive(false);
                        setAddNewProtocol(false);
                        setDeleteActiveProtocol(false);
                        setNewProtocol({});
                        setSelectedFileName(constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE);
                    })
                    .catch((err) => {
                        throw new Error(err.message);
                    })
            })
            .catch((err) => {
                throw new Error(err.message);
            })
                .finally(() => {
                    setNewProtocol({});
                    setAddNewProtocol(false);
                    setDeleteActiveProtocol(false);
                    setSaveButtonText(constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE);
            })
        }
    }

    function deleteProtocol() {
        setSaveButtonText(constants.ORG_SETTINGS.BUTTON_LOADING);
        const filteredProtocolName = isProtocolList.map(el => el.name)
        const body = {
            protocol: {
                enabled: isCheckboxActive,
                org_template_links: filteredProtocolName
            },
            org_id: org?.id
        }
        requestHelper(Organizations.updateProtocolSettings, body)
            .then(() => {
                reloadOrgPage();
                setSaveButtonActive(false);
                setAddNewProtocol(false);
                setDeleteActiveProtocol(false);
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setSaveButtonActive(false);
                setAddNewProtocol(false);
                setDeleteActiveProtocol(false);
                setSaveButtonText(constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE);
            })
    }

    function changeProtocolEnable() {
        setSaveButtonText(constants.ORG_SETTINGS.BUTTON_LOADING);
        const body = {
            protocol: {
                enabled: isCheckboxActive,
                org_template_links: org?.config?.protocol?.org_template_links
            },
            org_id: org?.id
        }
        requestHelper(Organizations.updateProtocolSettings, body)
            .then(() => {
                reloadOrgPage();
                setSaveButtonActive(false);
                setAddNewProtocol(false);
                setDeleteActiveProtocol(false);
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setSaveButtonActive(false);
                setAddNewProtocol(false);
                setDeleteActiveProtocol(false);
                setSaveButtonText(constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE);
            })
    }

    function saveChanges() {
        if (isDeleteActiveProtocol === true) {
            deleteProtocol();
        } else {
            changeProtocolEnable();
        }
    }

    function onHandleTemplateProtocolLink(protocolLink) {
        if (protocolLink !== undefined) {
            setTemplateLink(protocolLink)
        } else {
            setTemplateLink('')
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isAddNewProtocol === true) {
            isMounted && addNewProtocol()
        }
        return () => {
            isMounted = false;
        }
    },[isAddNewProtocol])

    useEffect(() => {
        let isMounted = true;
        const protocolName = templateLink.split('/');
        if (templateLink !== '') {
            if (protocolName[3].includes('_')) {
                const name = protocolName[3].split('_');
                if (name[1].includes('--')) {
                    const nameForDownload = name[1].replace(/--/g, ' ');
                    isMounted && setCurrentProtocolName(nameForDownload);
                } else {
                    isMounted && setCurrentProtocolName(name[1]);
                }
            } else {
                const name = protocolName[3];
                if (name.includes('--')) {
                    const nameForDownload = name.replace(/--/g, ' ');
                    isMounted && setCurrentProtocolName(nameForDownload);
                } else {
                    isMounted && setCurrentProtocolName(name);
                }
            }
        }
        return () => {
            isMounted = false;
        }
    }, [templateLink])

    useEffect(() => {
        let isMounted = true;
        if (currentProtocolName !== '') {
            isMounted && downloadFile(templateLink, currentProtocolName)
        }
        return () => {
            isMounted = false;
        }
    },[currentProtocolName])

    // function onCheckedProtocol(indexProtocol) {
    //     const currentProtocol = isProtocolList.find((value, index) => index === indexProtocol)
    //     if (currentProtocol.isChecked === false) {
    //         const changeProtocol = {...currentProtocol, isChecked: true}
    //         const updatedListProtocol = isProtocolList.map((value, index) => index === indexProtocol ? changeProtocol : value)
    //         setProtocolList(updatedListProtocol)
    //     } else if (currentProtocol.isChecked === true) {
    //         const changeProtocol = {...currentProtocol, isChecked: false}
    //         const updatedListProtocol = isProtocolList.map((value, index ) => index === indexProtocol ? changeProtocol : value)
    //         setProtocolList(updatedListProtocol)
    //     }
    // }

    return (
        <>
            {isOrgSuperAdmin ? (
                <div className="protocol-settings">
                    <h2 className="protocol-settings__heading">{constants.ORG_SETTINGS.PROTOCOL_SETTINGS}</h2>
                    <div className="protocol-settings__container">
                        <div className="protocol-settings__checkbox-container" onClick={handleChangeProtocolEnable}>
                            <div className={`protocol-settings__checkbox-icon ${isCheckboxActive && 'protocol-settings__checkbox-icon_active'}`} />
                            <p className="protocol-settings__checkbox-text">{constants.ORG_SETTINGS.FORM_PROTOCOL}</p>
                        </div>
                        <div className="protocol-settings-default-protocol">
                            <p className="protocol-settings-default-protocol__title">Шаблон протокола по умолчанию</p>
                            <p className="protocol-settings-default-protocol__link" onClick={() => onHandleTemplateProtocolLink(org?.config?.event_template_links?.default)}>{setCorrectNameProtocol(org?.config?.event_template_links?.default)}</p>
                        </div>
                        <div className="protocol-settings__files-container">
                            {isProtocolList !== undefined &&
                                <div className="protocol-settings__save-protocol-container">
                                    <p className="protocol-settings__save-protocol-heading">{constants.ORG_SETTINGS.CURRENT_PROTOCOL}</p>
                                    {isProtocolList?.map((el, i) => {
                                        return (
                                            <div key={i} className="protocol-settings__save-container">
                                                <div className="protocol-settings__save-name-input">
                                                    <p className="protocol-settings__save-protocol-name" title={el.name}>{setCorrectNameProtocol(el.name)}</p>
                                                </div>
                                                <div className="protocol-settings__save-icons">
                                                    <div onClick={() => onHandleTemplateProtocolLink(el.name)} className="protocol-settings__save-protocol-button" />
                                                    <img onClick={() => onHandleDeleteProtocol(i)} className="protocol-settings__delete-protocol-button" src={deleteIcon} alt={constants.ALT_ICON}/>
                                                </div>
                                            </div>
                                        )})}
                                </div>}
                            <div className="protocol-settings__download-file-container">
                                <p className="protocol-settings__download-file-label">{constants.ORG_SETTINGS.DOWNLOAD_NEW_TEMPLATE_PROTOCOL}</p>
                                <div className="protocol-settings__file-add-container">
                                    <input
                                        className="protocol-settings__file-add-input"
                                        id="file"
                                        type="file"
                                        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={(e) => onSelectFileHandler(e)}
                                    />
                                    <label htmlFor="file" className="protocol-settings__file-add-input-container">
                                        <div className="protocol-settings__file-add-input-file-name-container">
                                            <p className={`protocol-settings__file-add-input-file-name-text ${isFileSelected && 'protocol-settings__file-add-input-file-name-text_selected'}`}>{selectedFileName}</p>
                                        </div>
                                        <div className="protocol-settings__file-add-input-button">
                                            <p className='protocol-settings__file-add-input-button-text'>{constants.ORG_SETTINGS.LOAD_BUTTON}</p>
                                        </div>
                                    </label>
                                    {/*{isFileSelected && (*/}
                                    {/*    <div className="protocol-settings__success-container">*/}
                                    {/*        <div className="protocol-settings__success-icon" />*/}
                                    {/*        <p className="protocol-settings__success-label">{constants.ORG_SETTINGS.LOAD_READY}</p>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                        </div>
                        {isSaveButtonActive && (
                            <button className="protocol-settings__save-button" onClick={saveChanges}>{saveButtonText}</button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="protocol-settings">
                    <h2 className="protocol-settings__heading">{constants.ORG_SETTINGS.PROTOCOL_SETTINGS}</h2>
                    <div className="protocol-settings__container">
                        <div className="protocol-settings__checkbox-container-default">
                            <div className={`protocol-settings__checkbox-icon-default ${isCheckboxActive && 'protocol-settings__checkbox-icon_active-default'}`} />
                            <p className="protocol-settings__checkbox-text">{constants.ORG_SETTINGS.FORM_PROTOCOL}</p>
                        </div>
                        <div className="protocol-settings__files-container">
                            {isProtocolList !== undefined &&
                                <div className="protocol-settings__save-protocol-container">
                                    <p className="protocol-settings__save-protocol-heading">{constants.ORG_SETTINGS.CURRENT_PROTOCOL}</p>
                                    {isProtocolList?.map((el, i) => {
                                        return (
                                            <div key={i} className="protocol-settings__save-container">
                                                <div className="protocol-settings__save-name-input">
                                                    <p className="protocol-settings__save-protocol-name" title={el.name}>{setCorrectNameProtocol(el.name)}</p>
                                                </div>
                                                <div className="protocol-settings__save-icons">
                                                    <div onClick={() => onHandleTemplateProtocolLink(el.name)} className="protocol-settings__save-protocol-button" />
                                                </div>
                                            </div>
                                        )})}
                                </div>}
                            <div className="protocol-settings__download-file-container">
                                <p className="protocol-settings__download-file-label">{constants.ORG_SETTINGS.DOWNLOAD_NEW_TEMPLATE_PROTOCOL}</p>
                                <div className="protocol-settings__file-add-container">
                                    <input
                                        className="protocol-settings__file-add-input"
                                        id="file"
                                        type="file"
                                        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        onChange={(e) => onSelectFileHandler(e)}
                                        disabled={true}
                                    />
                                    <label htmlFor="file" className="protocol-settings__file-add-input-container-default">
                                        <div className="protocol-settings__file-add-input-file-name-container">
                                            <p className={`protocol-settings__file-add-input-file-name-text ${isFileSelected && 'protocol-settings__file-add-input-file-name-text_selected'}`}>{selectedFileName}</p>
                                        </div>
                                        <div className="protocol-settings__file-add-input-button-default">
                                            <p className='protocol-settings__file-add-input-button-text-default'>{constants.ORG_SETTINGS.LOAD_BUTTON}</p>
                                        </div>
                                    </label>
                                    {/*{isFileSelected && (*/}
                                    {/*    <div className="protocol-settings__success-container">*/}
                                    {/*        <div className="protocol-settings__success-icon" />*/}
                                    {/*        <p className="protocol-settings__success-label">{constants.ORG_SETTINGS.LOAD_READY}</p>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ProtocolSettings;
