import React, {useEffect, useState} from "react";
import './MaterialsVoteQuestionModal.css';
import MaterialsVoteQuestionModalDocuments
    from "./MaterialsVoteQuestionModalDocuments/MaterialsVoteQuestionModalDocuments";
import MaterialsVoteQuestionModalLinks from "./MaterialsVoteQuestionModalLinks/MaterialsVoteQuestionModalLinks";
import {useOnClickOutsideModal} from "../../../../utils/CustomHooks/useOutsideModal/useOutsideModal";

const MaterialsVoteQuestionModal = (props) => {

    const {
        active,
        setActive,
        currentMaterialsVote,
    } = props;

    const [typeDocument, setTypeDocument] = useState([])
    const [typeLink, setTypeLink] = useState([])

    useOnClickOutsideModal(active, () => setActive(false))

    useEffect(() => {
        let isMounted = true;
        if (currentMaterialsVote !== undefined) {
            const filteredCurrentMaterialsVoteDocument = currentMaterialsVote.filter(elem => elem.type === 'doc');
            isMounted && setTypeDocument(filteredCurrentMaterialsVoteDocument);
            const filteredCurrentMaterialsVoteLink = currentMaterialsVote.filter(elem => elem.type === 'link');
            isMounted && setTypeLink(filteredCurrentMaterialsVoteLink);
        }
        return () => {
            isMounted = false
        }
    }, [currentMaterialsVote]);

    return (
        <div className={active ? 'materials-vote-question-modal__wrapper active' : 'materials-vote-question-modal__wrapper'}>
            <div className={active ? 'materials-vote-question-modal__content active' : 'materials-vote-question-modal__content'} onClick={(e) => e.stopPropagation()}>
                <>
                    {typeDocument.map((item, i) => {
                        return (
                            <div key={i}>
                                <MaterialsVoteQuestionModalDocuments
                                    nameDocument={item.title}
                                    valueDocument={item.value}/>
                            </div>
                        )
                    })}
                </>
                <>
                    {typeLink.map((el, i) => {
                        return (
                            <div key={i}>
                                <MaterialsVoteQuestionModalLinks
                                    nameLink={el.value}/>
                            </div>
                        )
                    })}
                </>
            </div>
        </div>
    )
}
export default MaterialsVoteQuestionModal;