import React from "react";
import '../NoMatchRoutes/NoMatchRoutes.css';
import {useNavigate} from "react-router-dom";

export const NoMatchRoutes = (props) => {

    const {
        isLoggedIn,
        constants
    } = props;

    const navigate = useNavigate();

    const returnMainPage = () => {
        if (!isLoggedIn) {
            navigate('/auth')
        } else {
            navigate('/')
        }
    }

    return (
        <div className="no-match-routes__container">
            <div className="no-match-routes">
                <h1 className="no-match-routes__title">{constants.NO_MATCH_ROUTES.NO_MATCH_ROUTES_PAGE_NOT_FOUND}</h1>
                <p className="no-match-routes__information">{constants.NO_MATCH_ROUTES.NO_MATCH_ROUTES_MESSAGE}</p>
                <button className="no-match-routes__button" onClick={returnMainPage}>{constants.NO_MATCH_ROUTES.NO_MATCH_ROUTES_BACK_MAINPAGE}</button>
            </div>
        </div>
    )
}