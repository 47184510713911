export const setEmptyTextDetailVote = (blockname, constants) => {

    const textList = {
        'voting' : `${constants.DETAILS_VOTE.VOTING_EMPTY_INFO_VOTERS}`,
        'observers' : `${constants.DETAILS_VOTE.VOTING_EMPTY_INFO_OBSERVERS}`,
        'counting' : `${constants.DETAILS_VOTE.VOTING_EMPTY_INFO_COUNTERS}`,
    }

    for (let key in textList) {
        if (blockname === key) {
            return textList[key];
        }
    }

    return setEmptyTextDetailVote;
}