import {useEffect, useState} from "react";

function useSetTableListUsersDetailsVote(usersList, constants, changeStatusColumn) {

    const [value, setValue] = useState([]);
    
    useEffect(() => {
        let isMounted = true;
        const users = [];
        switch (changeStatusColumn) {
            case 'voting' : {
                usersList.forEach((el) => {
                    const newUser = {
                        id: el?.email,
                        email: el?.email,
                        first_name: el?.first_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}` : el?.first_name,
                        last_name: el?.last_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}` : el?.last_name,
                        second_name: el?.second_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}` : el?.second_name,
                        isRegistered: el?.isRegistered,
                        isVoted: el?.isVoted,
                        userFields: el?.userFields
                    }
                    users.push(newUser);
                })
                break;
            }
            case 'observers' : {
                usersList.forEach((el) => {
                    const newUser = {
                        id: el?.email,
                        email: el?.email,
                        first_name: el?.first_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}` : el?.first_name,
                        last_name: el?.last_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}` : el?.last_name,
                        second_name: el?.second_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}` : el?.second_name,
                        userFields: el?.userFields
                    }
                    users.push(newUser);
                })
                break;
            }
            case 'counting' : {
                usersList.forEach((el) => {
                    const newUser = {
                        id: el?.email,
                        email: el?.email,
                        first_name: el?.first_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}` : el?.first_name,
                        last_name: el?.last_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}` : el?.last_name,
                        second_name: el?.second_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}` : el?.second_name,
                        isSigned: el?.isSigned,
                        userFields: el?.userFields
                    }
                    users.push(newUser);
                })
                break;
            } default: isMounted && setValue([])
        }
        isMounted && setValue(users)
        return () => {
            isMounted = false;
        }
    },[usersList, constants, changeStatusColumn])

    return [value, setValue];
}
export default useSetTableListUsersDetailsVote;