import React from "react";
import closeIcon from "../../img/VotesPageBlockFilterModal_close_button.svg";

const GroupUsersDeleteModalUsers = (props) => {

    const {
        closeModal,
        onRemoveUserClick,
        isActiveModalDeleteUsers,
        user,
        constants,
        onOpenModalDeleteSomeUsers
    } = props

    return (
        <div className={isActiveModalDeleteUsers === user.id ? "group-users-delete-modal__container" : "group-users-delete-modal__container hide"}>
            <div className="group-users-delete-modal">
                <div className="group-users-delete-modal__title">
                    <h3 className="group-users-delete-modal__title-name">{constants.GROUP_USERS.GROUP_USERS_MODAL_SELECT_TYPE_DELETE}</h3>
                    <img onClick={closeModal} className="group-users-delete-modal__title-icon" src={closeIcon} alt={constants.ALT_ICON}/>
                </div>
                <div className="group-users-delete-modal__buttons">
                    <button onClick={() => onRemoveUserClick(user)} className="group-users-delete-modal__dont-save-btn">{constants.GROUP_USERS.GROUP_USERS_DELETE_BTN_SMALL}</button>
                    <button onClick={onOpenModalDeleteSomeUsers} className="group-users-delete-modal__dont-save-btn">{constants.GROUP_USERS.GROUP_USERS_MODAL_DELETE_SOME}</button>
                </div>
            </div>
        </div>
    )
}
export default GroupUsersDeleteModalUsers;