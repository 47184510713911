import React, { useEffect, useState } from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import VotesPageFilterSortButtons from "../VotesPageFilterSortButtons/VotesPageFilterSortButtons";
import MyVotesBlockForm from "../MyVotesBlockForm/MyVotesBlockForm";
import { Validation } from "../../utils/Validation/Validation";
import { onSetCountPageForSearch } from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const VotesPage = (props) => {
  const {
    constants,
    authAs,
    allEvents,
    handleCurrentEvents,
    handleDeleteEvents,
    formatDate,
    utcOffset,
    formatTime,
  } = props;

  const eventsSearchActive = Validation();
  const eventsSearchArchive = Validation();
  const [btnActiveVotes, setBtnActiveVotes] = useState(true);
  const [btnArchiveVotes, setBtnArchiveVotes] = useState(false);
  const [activeEvents, setActiveEvents] = useState([]);
  const [archiveEvents, setArchiveEvents] = useState([]);
  const [statusFilterArray, setStatusFilterArray] = useState([]);
  const [typeFilterArray, setTypeFilterArray] = useState([]);
  const [registerDateFrom, setRegisterDateFrom] = useState("");
  const [registerDateTo, setRegisterDateTo] = useState("");
  const [eventStartDateFrom, setEventStartDateFrom] = useState("");
  const [eventStartDateTo, setEventStartDateTo] = useState("");
  const [isResetAllCheckboxClick, setResetAllCheckboxClick] = useState(false);
  const [activeEventsSearchInput, setActiveEventsSearchInput] = useState("");
  const [archiveEventsSearchInput, setArchiveEventsSearchInput] = useState("");
  const [activeEventsForRender, setActiveEventsForRender] = useState([]);
  const [archiveEventsForRender, setArchiveEventsForRender] = useState([]);
  const [activeEmptyStateMessage, setActiveStateEmptyMessage] = useState(false);
  const [sortType, setSortType] = useState("");
  const [showResultsFrom, setShowResultsFrom] = useState(0);
  const [resultsShow, setResultsShow] = useState(5);
  const [result, setResult] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [selectedResultsShow, setSelectedResultsShow] = useState(5);
  const [showResultsFromArchive, setShowResultsFromArchive] = useState(0);
  const [resultsShowArchive, setResultsShowArchive] = useState(5);
  const [resultArchive, setResultArchive] = useState(5);
  const [pageCountArchive, setPageCountArchive] = useState(1);
  const [selectedResultsShowArchive, setSelectedResultsShowArchive] =
    useState(5);

  useEffect(() => {
    let isMounted = true;
    isMounted && splitEvents(allEvents);
    return () => {
      isMounted = false;
    };
  }, [allEvents]);

  function handleShowResultsFrom(value) {
    if (btnActiveVotes) {
      setShowResultsFrom(value);
    } else {
      setShowResultsFromArchive(value);
    }
  }

  function handleResultsShow(value) {
    if (btnActiveVotes) {
      setResultsShow(value);
    } else {
      setResultsShowArchive(value);
    }
  }

  function showPrevResults() {
    if (btnActiveVotes) {
      if (resultsShow <= result) {
        return resultsShow;
      } else {
        setShowResultsFrom(showResultsFrom - result);
        handleShowResultsFrom(showResultsFrom - result);
        setResultsShow(resultsShow - result);
        handleResultsShow(resultsShow - result);
        setPageCount(pageCount - 1);
      }
    } else {
      if (resultsShowArchive <= resultArchive) {
        return resultsShowArchive;
      } else {
        setShowResultsFromArchive(showResultsFromArchive - resultArchive);
        handleShowResultsFrom(showResultsFromArchive - resultArchive);
        setResultsShowArchive(resultsShowArchive - resultArchive);
        handleResultsShow(resultsShowArchive - resultArchive);
        setPageCountArchive(pageCountArchive - 1);
      }
    }
  }

  function showNextResults() {
    const sortList = btnActiveVotes
      ? activeEventsForRender
      : archiveEventsForRender;
    if (btnActiveVotes) {
      if (resultsShow >= sortList.length) {
        return resultsShow;
      } else {
        setShowResultsFrom(0 + resultsShow);
        handleShowResultsFrom(0 + resultsShow);
        setResultsShow(result + resultsShow);
        handleResultsShow(result + resultsShow);
        setPageCount(pageCount + 1);
      }
    } else {
      if (resultsShowArchive >= sortList.length) {
        return resultsShowArchive;
      } else {
        setShowResultsFromArchive(0 + resultsShowArchive);
        handleShowResultsFrom(0 + resultsShowArchive);
        setResultsShowArchive(resultArchive + resultsShowArchive);
        handleResultsShow(resultArchive + resultsShowArchive);
        setPageCountArchive(pageCountArchive + 1);
      }
    }
  }

  function onChoiceClick(value) {
    if (btnActiveVotes) {
      setResultsShow(value);
      handleResultsShow(value);
      setResult(value);
      setSelectedResultsShow(value);
      setShowResultsFrom(0);
      handleShowResultsFrom(0);
      setPageCount(1);
    } else {
      setResultsShowArchive(value);
      handleResultsShow(value);
      setResultArchive(value);
      setSelectedResultsShowArchive(value);
      setShowResultsFromArchive(0);
      handleShowResultsFrom(0);
      setPageCountArchive(1);
    }
  }

  function eventsSearchInput(value) {
    if (btnActiveVotes) {
      setActiveEventsSearchInput(value);
    } else if (btnArchiveVotes) {
      setArchiveEventsSearchInput(value);
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (btnActiveVotes) {
      if (activeEventsSearchInput === "") {
        isMounted && onChoiceClick(5);
        isMounted && setActiveEventsForRender(activeEvents);
      } else {
        const dataForRender = [];
        activeEvents.forEach((event) => {
          if (
            event?.title
              ?.toLowerCase()
              .includes(activeEventsSearchInput.toLowerCase())
          ) {
            dataForRender.push(event);
          }
        });
        isMounted &&
          onSetCountPageForSearch(dataForRender.length, onChoiceClick);
        isMounted && setActiveEventsForRender(dataForRender);
      }
    } else if (btnArchiveVotes) {
      if (archiveEventsSearchInput === "") {
        isMounted && onChoiceClick(5);
        isMounted &&
          setArchiveEventsForRender(
            archiveEvents.sort((a, b) =>
              b.event_end_time > a.event_end_time ? 1 : -1
            )
          );
      } else {
        const dataForRender = [];
        archiveEvents.forEach((event) => {
          if (
            event?.title
              ?.toLowerCase()
              .includes(archiveEventsSearchInput.toLowerCase())
          ) {
            dataForRender.push(event);
          }
        });
        isMounted &&
          onSetCountPageForSearch(dataForRender.length, onChoiceClick);
        isMounted &&
          setArchiveEventsForRender(
            dataForRender.sort((a, b) =>
              b.event_end_time > a.event_end_time ? 1 : -1
            )
          );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    activeEventsSearchInput,
    activeEvents,
    archiveEvents,
    archiveEventsSearchInput,
    btnActiveVotes,
    btnArchiveVotes,
  ]);

  function splitEvents(events) {
    setActiveEvents([]);
    setArchiveEvents([]);
    if (events.length !== 0) {
      events.forEach((event) => {
        if (event.status === "ended" || event.status === "quorum_unpresant") {
          setArchiveEvents((archiveEvents) => [...archiveEvents, event]);
        } else {
          setActiveEvents((activeEvents) => [...activeEvents, event]);
        }
      });
    }
  }

  function checkboxFilterArrayAdd(selectedCheckboxValue) {
    if (
      selectedCheckboxValue === "open" ||
      selectedCheckboxValue === "secret"
    ) {
      setTypeFilterArray([...typeFilterArray, selectedCheckboxValue]);
    } else {
      setStatusFilterArray([...statusFilterArray, selectedCheckboxValue]);
    }
  }

  function checkboxFilterArrayRemove(selectedCheckboxValue) {
    if (
      selectedCheckboxValue === "open" ||
      selectedCheckboxValue === "secret"
    ) {
      const filteredItems = typeFilterArray.filter(
        (typeFilter) => typeFilter !== selectedCheckboxValue
      );
      setTypeFilterArray(filteredItems);
    } else {
      const filteredItems = statusFilterArray.filter(
        (statusFilter) => statusFilter !== selectedCheckboxValue
      );
      setStatusFilterArray(filteredItems);
    }
  }

  function applyDateEventStartFilter(events) {
    const dateFrom =
      eventStartDateFrom !== "" ? new Date(eventStartDateFrom) : null;
    const dateTo = eventStartDateTo !== "" ? new Date(eventStartDateTo) : null;
    const filteredEvents = events.filter((event) => {
      const eventDate = new Date(event.event_start_time);
      return !(
        (dateFrom && dateFrom > eventDate) ||
        (dateTo && dateTo < eventDate)
      );
    });
    splitEvents(filteredEvents);
  }

  function applyDateRegisterFilter(events) {
    const dateFrom =
      registerDateFrom !== "" ? new Date(registerDateFrom) : null;
    const dateTo = registerDateTo !== "" ? new Date(registerDateTo) : null;
    const filteredEvents = events.filter((event) => {
      const eventDate = new Date(event.registration_start_time);
      return !(
        (dateFrom && dateFrom > eventDate) ||
        (dateTo && dateTo < eventDate)
      );
    });
    applyDateEventStartFilter(filteredEvents);
  }

  function applyTypeFilter(events) {
    if (typeFilterArray.length === 0) {
      applyDateRegisterFilter(events);
    } else {
      const filteredEvents = events.filter((event) =>
        typeFilterArray.find((typeFilter) => typeFilter === event.type)
      );
      applyDateRegisterFilter(filteredEvents);
    }
  }

  function onApplyFilterClick() {
    if (statusFilterArray.length === 0) {
      applyTypeFilter(allEvents);
    } else {
      const filteredEvents = allEvents.filter((event) =>
        statusFilterArray.find((statusFilter) => statusFilter === event.status)
      );
      applyTypeFilter(filteredEvents);
    }
  }

  function registerDateFromChange(evt) {
    setRegisterDateFrom(evt.target.value);
  }

  function registerDateToChange(evt) {
    setRegisterDateTo(evt.target.value);
  }

  function eventStartDateFromChange(evt) {
    setEventStartDateFrom(evt.target.value);
  }

  function eventStartDateToChange(evt) {
    setEventStartDateTo(evt.target.value);
  }

  function changeAllCheckbox() {
    setResetAllCheckboxClick(false);
  }

  function onResetFilterClick() {
    setStatusFilterArray([]);
    setTypeFilterArray([]);
    setRegisterDateFrom("");
    setRegisterDateTo("");
    setEventStartDateFrom("");
    setEventStartDateTo("");
    setResetAllCheckboxClick(true);
    splitEvents(allEvents);
  }

  function toggleActiveHide() {
    setBtnActiveVotes(true);
    setBtnArchiveVotes(false);
  }

  function toggleArchiveShow() {
    setBtnActiveVotes(false);
    setBtnArchiveVotes(true);
  }

  const clickSortTypeDec = (i) => {
    setSortType(i);
    if (btnActiveVotes) {
      switch (i) {
        case "-eventName":
          activeEventsForRender.sort((a, b) =>
            b.status.length > a.status.length ? 1 : -1
          );
          break;
        case "-orgName":
          activeEventsForRender.sort((a, b) =>
            b.owner.title > a.owner.title ? 1 : -1
          );
          break;
        case "-startReg":
          activeEventsForRender.sort((a, b) =>
            b.registration_start_time > a.registration_start_time ? 1 : -1
          );
          break;
        case "-startVote":
          activeEventsForRender.sort((a, b) =>
            b.event_start_time > a.event_start_time ? 1 : -1
          );
          break;
        case "-endReg":
          activeEventsForRender.sort((a, b) =>
            b.registration_end_time > a.registration_end_time ? 1 : -1
          );
          break;
        case "-endVote":
          activeEventsForRender.sort((a, b) =>
            b.event_end_time > a.event_end_time ? 1 : -1
          );
          break;
        default: {
        }
      }
    } else {
      if (btnArchiveVotes) {
        switch (i) {
          case "-eventName":
            archiveEventsForRender.sort((a, b) =>
              b.status.length > a.status.length ? 1 : -1
            );
            break;
          case "-orgName":
            archiveEventsForRender.sort((a, b) =>
              b.owner.title > a.owner.title ? 1 : -1
            );
            break;
          case "-startReg":
            archiveEventsForRender.sort((a, b) =>
              b.registration_start_time > a.registration_start_time ? 1 : -1
            );
            break;
          case "-startVote":
            archiveEventsForRender.sort((a, b) =>
              b.event_start_time > a.event_start_time ? 1 : -1
            );
            break;
          case "-endReg":
            archiveEventsForRender.sort((a, b) =>
              b.registration_end_time > a.registration_end_time ? 1 : -1
            );
            break;
          case "-endVote":
            archiveEventsForRender.sort((a, b) =>
              b.event_end_time > a.event_end_time ? 1 : -1
            );
            break;
          default: {
          }
        }
      }
    }
  };

  function clickSortTypeInc(i) {
    setSortType(i);
    if (btnActiveVotes) {
      switch (i) {
        case "eventName":
          activeEventsForRender.sort((a, b) =>
            a.status.length > b.status.length ? 1 : -1
          );
          break;
        case "orgName":
          activeEventsForRender.sort((a, b) =>
            a.owner.title > b.owner.title ? 1 : -1
          );
          break;
        case "startReg":
          activeEventsForRender.sort((a, b) =>
            a.registration_start_time > b.registration_start_time ? 1 : -1
          );
          break;
        case "startVote":
          activeEventsForRender.sort((a, b) =>
            a.event_start_time > b.event_start_time ? 1 : -1
          );
          break;
        case "endReg":
          activeEventsForRender.sort((a, b) =>
            a.registration_end_time > b.registration_end_time ? 1 : -1
          );
          break;
        case "endVote":
          activeEventsForRender.sort((a, b) =>
            a.event_end_time > b.event_end_time ? 1 : -1
          );
          break;
        default: {
        }
      }
    } else {
      if (btnArchiveVotes) {
        switch (i) {
          case "eventName":
            archiveEventsForRender.sort((a, b) =>
              a.status.length > b.status.length ? 1 : -1
            );
            break;
          case "orgName":
            archiveEventsForRender.sort((a, b) =>
              a.owner.title > b.owner.title ? 1 : -1
            );
            break;
          case "startReg":
            archiveEventsForRender.sort((a, b) =>
              a.registration_start_time > b.registration_start_time ? 1 : -1
            );
            break;
          case "startVote":
            archiveEventsForRender.sort((a, b) =>
              a.event_start_time > b.event_start_time ? 1 : -1
            );
            break;
          case "endReg":
            archiveEventsForRender.sort((a, b) =>
              a.registration_end_time > b.registration_end_time ? 1 : -1
            );
            break;
          case "endVote":
            archiveEventsForRender.sort((a, b) =>
              a.event_end_time > b.event_end_time ? 1 : -1
            );
            break;
          default: {
          }
        }
      }
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (btnActiveVotes) {
      if (activeEventsForRender.length !== 0) {
        isMounted && setActiveStateEmptyMessage(true);
      } else {
        isMounted && setActiveStateEmptyMessage(false);
      }
    } else if (btnArchiveVotes) {
      if (archiveEventsForRender.length !== 0) {
        isMounted && setActiveStateEmptyMessage(true);
      } else {
        isMounted && setActiveStateEmptyMessage(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [
    activeEventsForRender.length,
    archiveEventsForRender.length,
    btnActiveVotes,
    btnArchiveVotes,
  ]);

  return (
    <div className="votes-page__container _container">
      <GeneralTitleAllPages
        firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
        secondLetter={constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_VOTES}
        titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_VOTES}
      />
      <div className="votes-page__pagination-filter-sort-block">
        <VotesPageFilterSortButtons
          checkboxFilterArrayAdd={checkboxFilterArrayAdd}
          checkboxFilterArrayRemove={checkboxFilterArrayRemove}
          onApplyFilterClick={onApplyFilterClick}
          onResetFilterClick={onResetFilterClick}
          registerDateFromChange={registerDateFromChange}
          registerDateToChange={registerDateToChange}
          eventStartDateFromChange={eventStartDateFromChange}
          eventStartDateToChange={eventStartDateToChange}
          registerDateFrom={registerDateFrom}
          registerDateTo={registerDateTo}
          eventStartDateFrom={eventStartDateFrom}
          eventStartDateTo={eventStartDateTo}
          changeAllCheckbox={changeAllCheckbox}
          isResetAllCheckboxClick={isResetAllCheckboxClick}
          sortType={sortType}
          clickSortTypeInc={clickSortTypeInc}
          clickSortTypeDec={clickSortTypeDec}
          constants={constants}
        />
        <PaginationBlock
          eventsSearchActive={eventsSearchActive}
          eventsSearchArchive={eventsSearchArchive}
          eventsSearchInput={eventsSearchInput}
          btnActiveVotes={btnActiveVotes}
          btnArchiveVotes={btnArchiveVotes}
          sortList={
            btnActiveVotes ? activeEventsForRender : archiveEventsForRender
          }
          onChoiceClick={onChoiceClick}
          selectedResultsShow={
            btnActiveVotes ? selectedResultsShow : selectedResultsShowArchive
          }
          pageCount={btnActiveVotes ? pageCount : pageCountArchive}
          showPrevResults={showPrevResults}
          showNextResults={showNextResults}
        />
      </div>
      <div className="votes-page-block__main-content">
        <div className="votes-page-switch-buttons">
          <div>
            <h2
              onClick={() => {
                toggleActiveHide();
              }}
              className={
                btnActiveVotes
                  ? "active-votes-page-switch-buttons__button"
                  : "votes-page-switch-buttons__button"
              }
            >
              {constants.VOTES_PAGE.VOTES_PAGE_SWITCH_ACTIVE_BTN}{" "}
              <span className="_active-vote-bnt _mobile-active-vote-bnt">
                {constants.VOTES_PAGE.VOTES_PAGE_SWITCH_ACTIVE_BTN_MOBILE}
              </span>
            </h2>
          </div>
          <div>
            <h2
              onClick={() => {
                toggleArchiveShow();
              }}
              className={
                btnArchiveVotes
                  ? "active-votes-page-switch-buttons__button"
                  : "votes-page-switch-buttons__button"
              }
            >
              {constants.VOTES_PAGE.VOTES_PAGE_SWITCH_ARCHIVE_BTN}{" "}
              <span className="_active-vote-bnt _mobile-active-vote-bnt">
                {constants.VOTES_PAGE.VOTES_PAGE_SWITCH_ARCHIVE_BTN_MOBILE}
              </span>
            </h2>
          </div>
        </div>
        <span
          className={
            activeEmptyStateMessage
              ? "votes-page-block__empty-state-message active"
              : "votes-page-block__empty-state-message"
          }
        >
          {constants.VOTES_PAGE.VOTES_PAGE_EMPTY_MESSAGE_NOT_FOUND_VOTES}
        </span>
        {btnActiveVotes && (
          <>
            {activeEventsForRender
              .sort((a, b) =>
                b.event_start_time > a.event_start_time ? 1 : -1
              )
              .slice(showResultsFrom, resultsShow)
              .map((event) => (
                <MyVotesBlockForm
                  key={event.id}
                  votesData={event}
                  handleCurrentEvents={handleCurrentEvents}
                  handleDeleteEvents={handleDeleteEvents}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                  constants={constants}
                  authAs={authAs}
                />
              ))}
          </>
        )}
        {btnArchiveVotes && (
          <>
            {archiveEventsForRender
              .sort((a, b) =>
                b.event_start_time > a.event_start_time ? 1 : -1
              )
              .slice(showResultsFromArchive, resultsShowArchive)
              .map((event) => (
                <MyVotesBlockForm
                  key={event.id}
                  votesData={event}
                  handleCurrentEvents={handleCurrentEvents}
                  handleDeleteEvents={handleDeleteEvents}
                  formatDate={formatDate}
                  formatTime={formatTime}
                  utcOffset={utcOffset}
                  constants={constants}
                  authAs={authAs}
                />
              ))}
          </>
        )}
        {/*<p className={activeEmptyStateMessage ? "votes-page-block__main-content-show-more-button" : "votes-page-block__show-more active"}>{constants.VOTES_PAGE.VOTES_PAGE_SHOW_MORE_BTN}</p>*/}
        {/*<span className={activeEmptyStateMessage ? 'votes-page-block__empty-state-message active' : 'votes-page-block__empty-state-message'}>*/}
        {/*    {constants.VOTES_PAGE.VOTES_PAGE_SHOW_INFO_DO_NOT_VOTE}</span>*/}
      </div>
      <PaginationBlock
        eventsSearchActive={eventsSearchActive}
        eventsSearchArchive={eventsSearchArchive}
        eventsSearchInput={eventsSearchInput}
        btnActiveVotes={btnActiveVotes}
        btnArchiveVotes={btnArchiveVotes}
        sortList={
          btnActiveVotes ? activeEventsForRender : archiveEventsForRender
        }
        onChoiceClick={onChoiceClick}
        selectedResultsShow={
          btnActiveVotes ? selectedResultsShow : selectedResultsShowArchive
        }
        pageCount={btnActiveVotes ? pageCount : pageCountArchive}
        showPrevResults={showPrevResults}
        showNextResults={showNextResults}
      />
    </div>
  );
};
export default VotesPage;
