import React from "react";
import "./DetailsVotesPageResultGridTable.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
const DetailsVotesPageResultGridTable2 = (props) => {
  const { answers, question, onlyLastTable = false } = props;

  const isRadio = question.template === "radio_grid";

  const answersArray = answers[0].columns.map((it, i) => ({
    id: it.id,
    value: i + 1,
  }));

  const sortResults = (array) => {
    const sortedResults = array
      .slice()
      .sort(
        (a, b) =>
          b.columns.reduce(
            (acc, o) =>
              +o.favor >= 0 && +o.value >= 0 ? acc + +o.favor * +o.value : acc,
            0
          ) -
          a.columns.reduce(
            (acc, o) =>
              +o.favor >= 0 && +o.value >= 0 ? acc + +o.favor * +o.value : acc,
            0
          )
      );
    return sortedResults;
  };

  const sortUserAnswers = (userAnswers) => {
    const sortedAnswers = sortResults(answers).map((answer) =>
      userAnswers.find((it) => it.id === answer.id)
    );
    return sortedAnswers;
  };

  return (
    <>
      {!onlyLastTable && (
        <>
          <h3
            className="details-votes-page-result-question-grid__title"
            style={{ marginBottom: "15px" }}
          >
            Ответы участников
          </h3>

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button details-vote-questions__export-protocol-btn"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Скачать в Excel"
          />
          <div
            className="details-votes-page-result-question-grid__tables-container table-answers"
            style={{ marginTop: 20, marginBottom: 50 }}
          >
            <div
              className="details-votes-page-result-grid-table__wrapper"
              style={{ borderBottom: "none", maxWidth: "100%" }}
            >
              <table className="details-votes-page-result-grid-table__table table2">
                <tbody>
                  <tr
                    style={{
                      borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                    }}
                  >
                    <th style={{ whiteSpace: "nowrap", padding: "20px" }}>
                      Участники / Доклады
                    </th>
                    <th style={{ whiteSpace: "nowrap", padding: "20px" }}>
                      Проект
                    </th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        padding: "20px",
                        width: 110,
                      }}
                    >
                      Баллы
                    </th>
                  </tr>
                  {question.users.map((user) => {
                    return user.answers.map((answer, i) => (
                      <React.Fragment key={answer.id}>
                        <tr
                          style={{
                            borderBottom:
                              i === question.users.length - 1
                                ? "none"
                                : "1px solid rgba(54, 59, 77, 0.3)",
                          }}
                        >
                          <td style={{ padding: "10px 20px", width: "35%" }}>
                            {i === 0 && (user.name || "Имя не указано")}
                          </td>

                          <td style={{ padding: "20px " }}>
                            {answers.find((it) => it.id === answer.id).title ||
                              "-"}
                          </td>

                          <td
                            style={{
                              padding: "20px 10px",
                              textAlign: "center",
                            }}
                            key={answer.id}
                          >
                            <p style={{ display: "flex", gap: "5px" }}>
                              {answer.values.map((value) => (
                                <span key={answer.id}>
                                  {answersArray.find((it) => it.id === value)
                                    .value || "-"}
                                </span>
                              ))}
                            </p>
                          </td>

                          {/* {user.answers.map((answer) => (
                            <td style={{ padding: "20px 10px" }} key={answer.id}>
                              {answersArray.find((it) => it.id === answer.values[0])
                                .value || "-"}
                            </td>
                          ))} */}
                        </tr>
                      </React.Fragment>
                    ));
                  })}

                  {isRadio &&
                    sortResults(answers).map((answer, i) => (
                      <tr
                        key={answer.id}
                        style={{
                          fontWeight: "bold",
                          borderBottom:
                            i === answers.length - 1
                              ? "none"
                              : "1px solid rgba(54, 59, 77, 0.3)",
                        }}
                      >
                        <td style={{ padding: "20px " }}>
                          {i === 0 && `Итого:`}
                        </td>
                        <td style={{ padding: "20px " }}>{answer.title}</td>
                        <td style={{ padding: "20px " }}>
                          {answer.columns.reduce(
                            (acc, o) =>
                              +o.favor >= 0 && +o.value >= 0
                                ? acc + +o.favor * +o.value
                                : acc,
                            0
                          )}
                        </td>
                      </tr>
                    ))}

                  {/* <tr style={{ fontWeight: "bold" }}>
                    <td style={{ padding: "20px" }}>Итого:</td>
                    {sortedAnswers.map((answer) => (
                      <td key={answer.id} style={{ padding: "20px 10px" }}>
                        {answer.title}
                      </td>
                    ))}
    
                    {sortedAnswers.map((answer) => (
                      <td key={answer.id} style={{ padding: "20px 10px" }}>
                        {answer.columns.reduce(
                          (acc, o) =>
                            +o.favor >= 0 && +o.value >= 0
                              ? acc + +o.favor * +o.value
                              : acc,
                          0
                        )}
                      </td>
                    ))}
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          {/* <h3
            className="details-votes-page-result-question-grid__title"
            style={{ marginBottom: "15px" }}
          >
            Ответы участников
          </h3> */}

          <div
            className="details-votes-page-result-question-grid__tables-container table-answers"
            style={{ marginBottom: 50, display: "none" }}
          >
            <div
              className="details-votes-page-result-grid-table__wrapper"
              style={{ borderBottom: "none", maxWidth: "100%" }}
            >
              <table
                className="details-votes-page-result-grid-table__table table2"
                id="table-to-xls"
              >
                <tbody>
                  <tr
                    style={{
                      borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                    }}
                  >
                    <th style={{ whiteSpace: "nowrap" }}>
                      Участники / Доклады
                    </th>
                    {answers &&
                      sortResults(answers).map((answer) => (
                        <th key={answer.id} style={{ padding: 10 }}>
                          {answers.find((it) => it.id === answer.id).title ||
                            "-"}
                        </th>
                      ))}
                  </tr>
                  {question.users.map((user, i) => (
                    <tr
                      key={user.id}
                      style={{
                        borderBottom:
                          i === question.users.length - 1
                            ? "none"
                            : "1px solid rgba(54, 59, 77, 0.3)",
                      }}
                    >
                      <td style={{ padding: "10px 20px" }}>
                        {user.name || "Имя не указано"}
                      </td>

                      {user.answers &&
                        sortUserAnswers(user.answers).map((answer) => (
                          <td style={{ padding: "20px 10px" }} key={answer.id}>
                            <p style={{ display: "flex", gap: "5px" }}>
                              {answer.values?.map((value, i) => (
                                <span key={`${i}-${value}`}>
                                  {answersArray.find((it) => it.id === value)
                                    .value || "-"}
                                </span>
                              ))}
                            </p>
                          </td>
                        ))}
                    </tr>
                  ))}

                  {isRadio && (
                    <tr style={{ fontWeight: "bold" }}>
                      <td style={{ padding: "20px" }}>Итого:</td>

                      {sortResults(answers).map((answer) => (
                        <td key={answer.id} style={{ padding: "20px 10px" }}>
                          {answer.columns.reduce(
                            (acc, o) =>
                              +o.favor >= 0 && +o.value >= 0
                                ? acc + +o.favor * +o.value
                                : acc,
                            0
                          )}
                        </td>
                      ))}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {isRadio && (
        <>
          <h3
            className="details-votes-page-result-question-grid__title"
            style={{ marginBottom: "15px" }}
          >
            Сумма баллов
          </h3>

          <div style={{ display: "flex", gap: "16px" }}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button details-vote-questions__export-protocol-btn"
              table="table-to-xls-sum"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Скачать в Excel"
            />
            {!onlyLastTable && (
              <Link
                style={{ marginBottom: "15px" }}
                to="/results"
                target="_blank"
                className="details-vote-statistics-vote-update-statistics-vote__button"
              >
                Открыть в отдельном окне
              </Link>
            )}
          </div>

          <div
            className="details-votes-page-result-question-grid__tables-container"
            style={{ marginTop: 15, width: "100%" }}
          >
            <div
              className="details-votes-page-result-grid-table__wrapper"
              style={{ borderBottom: "none", width: "100%", maxWidth: "100%" }}
            >
              <table
                className="details-votes-page-result-grid-table__table table2"
                id="table-to-xls-sum"
              >
                <tbody>
                  <tr
                    style={{
                      borderBottom: "1px solid rgba(54, 59, 77, 0.3)",
                    }}
                  >
                    <th>№</th>
                    <th>Доклад</th>
                    <th style={{ width: 110 }}>Баллы</th>
                  </tr>
                  {sortResults(answers).map((answer, i) => (
                    <tr
                      key={answer.id}
                      style={{
                        borderBottom:
                          i === answers.length - 1
                            ? "none"
                            : "1px solid rgba(54, 59, 77, 0.3)",
                        fontWeight: 700,
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{answer.title}</td>
                      <td>
                        {answer.columns.reduce(
                          (acc, o) =>
                            +o.favor >= 0 && +o.value >= 0
                              ? acc + +o.favor * +o.value
                              : acc,
                          0
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailsVotesPageResultGridTable2;
