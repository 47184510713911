import React, {useEffect, useState} from "react";
import MaterialsVoteQuestion from "../VotesStatusComponents/MaterialsVoteQuestion/MaterialsVoteQuestion";

const DetailsVoteQuestionsCard = (props) => {

    const {
        nameQuestion,
        rulesQuestion,
        typeCard,
        rows,
        columns,
        materials
    } = props;

    const [activeTypeRow, setActiveTypeRow] = useState(false);
    const [activeTypeGrid, setActiveTypeGrid] = useState(true);
    const [activeRadioGrid, setActiveRadioGrid] = useState(false);
    const [ruleText, setRuleText] = useState('');

    useEffect(() => {
        let isMounted = true;

        const getStyleCard = document.querySelectorAll('.details-vote-questions-card');
        const lastCard = getStyleCard[getStyleCard.length - 1]
        lastCard.style.border = 'none'
        lastCard.style.padding = '0'
        lastCard.style.margin = '0'

        switch (typeCard) {
            case 'none' :
                isMounted && setActiveTypeRow(true)
                isMounted && setActiveTypeGrid(false)
            break;
            case 'ynq' :
                isMounted && setActiveTypeRow(true)
                isMounted && setActiveTypeGrid(false)
            break;
            case 'position_single' :
                isMounted && setActiveTypeRow(true)
                isMounted && setActiveTypeGrid(false)
            break;
            case 'position_multiple' :
                isMounted && setActiveTypeRow(true)
                isMounted && setActiveTypeGrid(false)
            break;
            case 'same_positions' :
                isMounted && setActiveTypeRow(true)
                isMounted && setActiveTypeGrid(false)
            break;
            case 'grid' :
                isMounted && setActiveTypeGrid(true)
                isMounted && setActiveTypeRow(false)
            break;
            case 'radio_grid' :
                isMounted && setActiveTypeGrid(true)
                isMounted && setActiveTypeRow(false)
                isMounted && setActiveRadioGrid(true)
            break;
            default: return typeCard
        }
        return () => {
            isMounted = false
        }
    }, [typeCard])

    function simpleQuestion(rules) {
        setRuleText('Необходимо выбрать ровно ' + rules.pick_eq);
    }

    function positionSingleQuestion(rules) {
        setRuleText('Количество должностных позиций доступных для выбора ' + rules.pick_le);
    }

    function positionMultipleQuestion(rules) {
        setRuleText('Количество кандидатов доступных для выбора ' + rules.pick_le);
    }

    function samePositionsQuestion(rules) {
        setRuleText('Количество кандидатов доступных для выбора ' + rules.pick_le);
    }

    function arbitraryQuestionNoRules() {
        setRuleText('Необходимо выбрать один или несколько');
    }

    function arbitraryQuestionLessThan(rules) {
        setRuleText('Необходимо выбрать меньше чем ' + rules.pick_lt);
    }

    function arbitraryQuestionMoreThan(rules) {
        setRuleText('Необходимо выбрать больше чем ' + rules.pick_gt);
    }

    function arbitraryQuestionLessOrEqual(rules) {
        setRuleText('Необходимо выбрать меньше или ровно ' + rules.pick_le);
    }

    function arbitraryQuestionMoreOrEqual(rules) {
        setRuleText('Необходимо выбрать больше или ровно ' + rules.pick_ge);
    }

    function arbitraryQuestionFromTo(rules) {
        setRuleText(`Необходимо выбрать от ${rules.pick_gt} до ${rules.pick_lt}`);
    }

    function arbitraryQuestionFromEqualToEqual(rules) {
        setRuleText(`Необходимо выбрать от ${rules.pick_ge} (включительно) до ${rules.pick_le} (включительно)`);
    }

    function arbitraryQuestionFromEqualTo(rules) {
        setRuleText(`Необходимо выбрать от ${rules.pick_ge} (включительно) до ${rules.pick_lt}`);
    }

    function arbitraryQuestionFromToEqual(rules) {
        setRuleText(`Необходимо выбрать от ${rules.pick_gt} до ${rules.pick_le} (включительно)`);
    }

    function arbitraryQuestionFewRules(rules) {
        if (
            rules.pick_gt !== -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt !== -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionFromTo(rules);
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge !== -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le !== -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionFromEqualToEqual(rules);
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge !== -1 &&
            rules.pick_lt !== -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionFromEqualTo(rules);
        } else if (
            rules.pick_gt !== -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le !== -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionFromToEqual(rules);
        }
    }

    function arbitraryQuestion(rules) {
        if (
            rules.pick_gt === -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionNoRules();
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq !== -1
        ) {
            simpleQuestion(rules);
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt !== -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionLessThan(rules);
        } else if (
            rules.pick_gt !== -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionMoreThan(rules);
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge === -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le !== -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionLessOrEqual(rules);
        } else if (
            rules.pick_gt === -1 &&
            rules.pick_ge !== -1 &&
            rules.pick_lt === -1 &&
            rules.pick_le === -1 &&
            rules.pick_eq === -1
        ) {
            arbitraryQuestionMoreOrEqual(rules);
        } else {
            arbitraryQuestionFewRules(rules);
        }
    }

    useEffect(() => {
        let isMounted = true;
            if (typeCard === 'ynq') {
                isMounted && simpleQuestion(rulesQuestion);
            } else if (typeCard === 'none') {
                isMounted && arbitraryQuestion(rulesQuestion);
            } else if (typeCard === 'position_single') {
                isMounted && positionSingleQuestion(rulesQuestion);
            } else if (typeCard === 'position_multiple') {
                isMounted && positionMultipleQuestion(rulesQuestion);
            } else if (typeCard === 'same_positions') {
                isMounted && samePositionsQuestion(rulesQuestion);
            }
            return () => {
                isMounted = false;
            }
        },
        // eslint-disable-next-line
        [rulesQuestion, typeCard])

    return (
        <div className="details-vote-questions-card">
            <div className="details-vote-questions-card__question">
                <p className="details-vote-questions-card__question-name">{nameQuestion}</p>
                <p className="details-vote-questions-card__question-note">{ruleText}</p>
                <div className={materials.length !== 0 ? "details-vote-questions-card__materials-vote" : "details-vote-questions-card__materials-vote hide"}>
                    <MaterialsVoteQuestion
                        currentMaterialsVote={materials}
                        materialsVoteName={'Материалы вопроса'}/>
                </div>
            </div>
            {activeTypeRow && (
                <>
                    {rows.map(el => {
                        return (
                            <div key={el?.id} className="details-vote-questions-card__row-answer">
                                <div className="details-vote-questions-card__row-answer-variant">
                                    <div className="details-vote-questions-card__row-answer-variant-square"></div>
                                    <p className="details-vote-questions-card__row-answer-variant-name">{el.value}</p>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
            {activeTypeGrid && (
                <>
                    <div className="details-vote-questions-card__grid">
                        <div className="details-vote-questions-card__grid-answer-header">
                            <p className="details-vote-questions-card__grid-answer-header-empty-block"></p>
                            {columns.map(el => (
                                <p key={el?.id} className="details-vote-questions-card__grid-answer-header-item">{el?.value}</p>
                            ))}
                        </div>
                        <div className="details-vote-questions-card__grid-answer-row">
                            {rows.map(el => (
                                <div key={el?.id} className="details-vote-questions-card__grid-answer-row-item">
                                    <p className="details-vote-questions-card__grid-answer-row-name">{el?.value}</p>
                                    {columns.map(el => (
                                        <div key={el?.id} className="details-vote-questions-card__grid-answer-row-checkbox">
                                            <div className={activeRadioGrid ? "details-vote-questions-card__grid-answer-row-radio" : "details-vote-questions-card__grid-answer-row-checkmark"}/>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
export default DetailsVoteQuestionsCard;