import React, {useEffect, useState} from "react";
import closeIcon from "../../img/VotesPageBlockFilterModal_close_button.svg";
import RadioCheckBoxIntervalType from "./RadioCheckBoxIntervalType/RadioCheckBoxIntervalType";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import {timeList} from "../../utils/TimeListProlongVote/timeList";
import useShowProlongRadioButton from "../../utils/CustomHooks/useShowProlongRadioButton/useShowProlongRadioButton";

const DetailsVoteGeneralInfoOptionProlongVoteModal = (props) => {

    const {
        isActiveOptionProlongModal,
        onHandleChangeUpdateEvent,
        constants,
        closeModal,
        isLoading,
        currentEventData
    } = props;

    const [isActiveRadioCheck, setActiveRadioCheck] = useState('prolong_vote');
    const [isActiveSelectTimeList, setActiveSelectTimeList] = useState(false);
    const [isSelectedTime, setSelectedTime] = useState(1);
    const [isSelectedLabel, setSelectedLabel] = useState('1 час');
    const [isTypeInterval, setTypeInterval] = useShowProlongRadioButton(currentEventData, constants)

    const onHandleGetValueCheck = (value) => {
        switch (value) {
            case 'prolong' : setActiveRadioCheck('prolong')
                break;
            case 'prolong_vote': setActiveRadioCheck('prolong_vote')
                break;
            case 'prolong_reg' : setActiveRadioCheck('prolong_reg')
                break;
            default: {}
        }
    }

    useEffect(() => {
        let isMounted = true;
        if(isActiveOptionProlongModal === false) {
            isMounted && setActiveRadioCheck('prolong_vote')
            isMounted && setSelectedTime(1);
            isMounted && setSelectedLabel('1 час');
        }
        return () => {
            isMounted = false;
        }
    }, [isActiveOptionProlongModal])

    const onHandleGetTimeValue = (value, label) => {
        setSelectedTime(value)
        setSelectedLabel(label)
    }

    return (
        <div className={isActiveOptionProlongModal ? "details-vote-general-info-option-prolong-vote-modal__container" : "details-vote-general-info-option-prolong-vote-modal__container hide"}>
            <div className="details-vote-general-info-option-prolong-vote-modal">
                <div className="details-vote-general-info-option-prolong-vote-modal__title">
                    <h3 className="details-vote-general-info-option-prolong-vote-modal__title-name">{constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_TITLE}</h3>
                    <img onClick={() => closeModal()} className="details-vote-general-info-option-prolong-vote-modal__title-icon" src={closeIcon} alt={constants.ALT_ICON}/>
                </div>
                <div className="details-vote-general-info-option-prolong-vote-modal__variant-interval">
                    <p className="details-vote-general-info-option-prolong-vote-modal__variant-interval-label">{constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_INTERVAL_PROLONG}</p>
                    <div className="details-vote-general-info-option-prolong-vote-modal__variant-interval-select-type">
                        {isTypeInterval.map(el => {
                            return (
                                <div key={el.id} className="details-vote-general-info-option-prolong-vote-modal__select-type-items">
                                        <RadioCheckBoxIntervalType
                                            onHandleGetValueCheck={onHandleGetValueCheck}
                                            isActiveRadioCheck={isActiveRadioCheck}
                                            value={el.value}
                                            setActiveRadioCheck={setActiveRadioCheck}
                                        />
                                    <p className="details-vote-general-info-option-prolong-vote-modal__select-type-label">{el.label}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="add-new-vote__select-role">
                    <div onClick={() => setActiveSelectTimeList(!isActiveSelectTimeList)} className="add-new-vote__time-zone-select-container">
                        <p className="add-new-vote__time-zone-select-value">{isSelectedLabel}</p>
                        <img className="add-new-vote__time-zone-select-arrow" src={row_input_select_role} alt="Стрелочка открытия меню" />
                        <div className={isActiveSelectTimeList ? "add-new-vote__time-zone-options-container" : "add-new-vote__time-zone-options-container hidden"}>
                            {timeList.map(el => {
                                return (
                                    <div key={el.id} className="add-new-vote__time-zone-option-container" onClick={() => onHandleGetTimeValue(el.value, el.label)}>
                                        <p className="add-new-vote__time-zone-option">{el.label}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="details-vote-general-info-option-prolong-vote-modal__buttons">
                    <button onClick={() => onHandleChangeUpdateEvent(isActiveRadioCheck, isSelectedTime)}
                            className={isLoading === 'prolong'
                                || isLoading === 'prolong_vote'
                                || isLoading === 'prolong_reg' ? "details-vote-general-info-option-prolong-vote-modal__dont-save-btn active" : "details-vote-general-info-option-prolong-vote-modal__dont-save-btn"}>
                            {isLoading === 'prolong'
                                || isLoading === 'prolong_vote'
                                || isLoading === 'prolong_reg' ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_SAVE_BTN}`}
                    </button>
                    <button onClick={() => closeModal()}
                            className={isLoading === 'prolong'
                                || isLoading === 'prolong_vote'
                                || isLoading === 'prolong_reg' ? "details-vote-general-info-option-prolong-vote-modal__dont-save-btn active" : "details-vote-general-info-option-prolong-vote-modal__dont-save-btn"}>
                                {constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_CANCEL_BTN}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default DetailsVoteGeneralInfoOptionProlongVoteModal;