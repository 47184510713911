import * as Organizations from "../../Api/Organizations";

export const orgSettingsGetUsers = (requestHelper, body, setOrganizationUser) => {
    requestHelper(Organizations.getOrganizationStatisticsUsers, body)
        .then((data) => {
            if (data.status === 'failure') {
                setOrganizationUser([]);
            } else {
                setOrganizationUser(data);
            }
        })
        .catch((err) => {
            throw new Error(err.message);
        })
}