import * as AddEvent from "../../Api/AddEvent";

export const createNewVote = (pathname, requestHelper, body, runUpdateDataEvents, navigate, setErrorMessage, constants, setLoading) => {

        requestHelper(AddEvent.addNewEvent, body)
            .then((data) => {
                if (data.status === 'ok') {
                    runUpdateDataEvents();
                    navigate('/list-votes');
                    window.scrollTo(0, 0);
                } else {
                    console.log(data.text);
                    if (data.text.includes("java.lang.Exception: No valid proposal responses received")) {
                        setErrorMessage(constants.ADD_NEW_VOTE.EVENT_LIMIT_ERR);
                        setLoading('');
                    } else {
                        setErrorMessage(constants.ADD_NEW_VOTE.SOMETHING_WENT_WRONG_ERR);
                        setLoading('');
                    }
                }
            })
            .catch((err) => {
                setLoading('');
                throw new Error(err.message);
            })
            .finally(() => {
                runUpdateDataEvents();
                setLoading('');
            })

}