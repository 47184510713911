import React from "react";
import closeIcon from "../../../img/VotesPageBlockFilterModal_close_button.svg";

const DetailsVoteResultsSendOpinionModal = (props) => {

    const {
        isActiveSendOpinionModal,
        closeModal,
        isOpinionText,
        constants,
        isMessageError,
        onHandleSingProtocolWithOpinion
    } = props;

    return (
        <div className={isActiveSendOpinionModal ? "details-vote-results-send-opinion-modal__container" : "details-vote-results-send-opinion-modal__container hide"}>
            <div className="details-vote-results-send-opinion-modal">
                <div className="details-vote-results-send-opinion-modal__title">
                    <h3 className="details-vote-results-send-opinion-modal__title-name">{constants.DETAILS_VOTE.RESULTS_RESULTS_SEND_OPINION_MODAL_TITLE}</h3>
                    <img onClick={closeModal} className="details-vote-results-send-opinion-modal__title-icon" src={closeIcon} alt={constants.ALT_ICON}/>
                </div>
                <div className="details-vote-results-send-opinion-modal__input-textarea-block">
                 <textarea
                     className="details-vote-results-send-opinion-modal__input-text"
                     placeholder={constants.DETAILS_VOTE.RESULTS_RESULTS_SEND_OPINION_MODAL_PLACEHOLDER}
                     value={isOpinionText.value}
                     onChange={isOpinionText.onChange}
                 />
                    <p className="details-vote-results-send-opinion-modal__error-message">{isMessageError}</p>
                </div>
                <div className="details-vote-results-send-opinion-modal__buttons">
                    <button onClick={() => onHandleSingProtocolWithOpinion('signModal', isOpinionText.value)} className="details-vote-results-send-opinion-modal__dont-save-btn">{constants.DETAILS_VOTE.RESULTS_RESULTS_SEND_OPINION_MODAL_SEND_BTN}</button>
                    <button onClick={closeModal} className="details-vote-results-send-opinion-modal__dont-save-btn">{constants.DETAILS_VOTE.RESULTS_RESULTS_SEND_OPINION_MODAL_CANCEL_BTN}</button>
                </div>
            </div>
        </div>
    )
}
export default DetailsVoteResultsSendOpinionModal;