import {useEffect, useState} from "react";

function useShowVariantsAnswerInCreatedQuestion(question) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;
        if (question.options.columns.length !== 0) {
                isMount && setValue(true)
        } else {
                isMount && setValue(false)
            }
        return () => {
            isMount = false;
        }
    },[question])

    return [value, setValue];
}
export default useShowVariantsAnswerInCreatedQuestion;