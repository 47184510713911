import {useEffect, useState} from "react";

function useSetRuleQuestionEQ(startValueInput, endValueInput, activeRuleSelect) {

    const [value, setValue] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (activeRuleSelect === true && startValueInput === endValueInput) {
            isMount && setValue(startValueInput)
        } else {
                setValue(-1)
            }

        return () => {
            isMount = false;
        }
    },[startValueInput, endValueInput, activeRuleSelect])

    return [value, setValue];
}

export default useSetRuleQuestionEQ;