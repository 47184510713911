import {useEffect, useState} from "react";

function useEndRegShowButton(currentEventData) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;
        if (currentEventData?.status === 'registration') {
            if (currentEventData?.onButton === true) {
                isMount && setValue(false)
            } else if (currentEventData?.config?.event?.combined_time === true) {
                isMount && setValue(false)
            } else if (currentEventData?.isRegistration === false) {
                isMount && setValue(false)
            }
            else {
                isMount && setValue(true)
            }
        } else if (currentEventData?.status === 'event waiting') {
            isMount && setValue(false)
        } else if  (currentEventData?.status === 'voting') {
            isMount && setValue(false)
        } else if (currentEventData?.status === 'ended') {
            isMount && setValue(false)
        }
        return () => {
            isMount = false;
        }
    },[currentEventData])

    return [value, setValue];
}

export default useEndRegShowButton;