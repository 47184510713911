import {useEffect, useState} from "react";

function useShowEditProfileButton(authUser, selectedUser) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;

        if (authUser?.authAs === "superAdmin") {
                isMount && setValue(true)
        } else if (authUser?.id === selectedUser?.id) {
            isMount && setValue(true)
        } else {
            isMount && setValue(false)
        }

        return () => {
            isMount = false;
        }
    },[authUser, selectedUser])

    return [value, setValue];
}

export default useShowEditProfileButton;