import React, { useEffect, useState } from "react";
import * as Organizations from '../../Api/Organizations';
import {useOnClickOutsideModal} from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";
import MailingSettingsForMail from "./MailingSettingsForMail/MailingSettingsForMail";
import MailingSettingsForPhone from "./MailingSettingsForPhone/MailingSettingsForPhone";

const MailingSettings = (props) => {
    const {
        constants,
        requestHelper,
        org,
        reloadOrgPage,
        isOrgSuperAdmin
    } = props;

    const [isCreateVoteActiveEmail, setCreateVoteActiveEmail] = useState(false);
    const [isStartVoteActiveEmail, setStartVoteActiveEmail] = useState(false);
    const [isEndVoteActiveEmail, setEndVoteActiveEmail] = useState(false);
    const [isStartRegActiveEmail, setStartRegActiveEmail] = useState(false);
    const [isEndRegActiveEmail, setEndRegActiveEmail] = useState(false);
    const [notificationTimeEmail, setNotificationTimeEmail] = useState([]);

    const [isCreateVoteActiveSms, setCreateVoteActiveSms] = useState(false);
    const [isStartVoteActiveSms, setStartVoteActiveSms] = useState(false);
    const [isEndVoteActiveSms, setEndVoteActiveSms] = useState(false);
    const [isStartRegActiveSms, setStartRegActiveSms] = useState(false);
    const [isEndRegActiveSms, setEndRegActiveSms] = useState(false);
    const [notificationTimeSms, setNotificationTimeSms] = useState([]);
    
    const [isSaveButtonLoading, setSaveButtonLoading] = useState(false);
    const [isSaveButtonActive, setSaveButtonActive] = useState(false);
    const [isTextMessage, setTextMessage] = useState('');
    const [isActiveSelectedAlertVoteEmail, setActiveSelectedAlertVoteEmail] = useState(null);
    const [isActiveSelectedAlertVoteSms, setActiveSelectedAlertVoteSms] = useState(null);
    useOnClickOutsideModal(isActiveSelectedAlertVoteEmail, () => setActiveSelectedAlertVoteEmail(null));
    useOnClickOutsideModal(isActiveSelectedAlertVoteSms, () => setActiveSelectedAlertVoteSms(null));

    function idGenerate(arr) {
        if (arr.length < 1) {
            return 1;
        } else {
            const allIdArray = arr.map((arr) => {
                return arr.id
            });
            return Math.max(...allIdArray) + 1;
        }
    }

    useEffect(() => {
        let isMounted = true;
            isMounted && setCreateVoteActiveEmail(org?.config?.mail?.on_creating);
            isMounted && setStartVoteActiveEmail(org?.config?.mail?.on_start_voting);
            isMounted && setEndVoteActiveEmail(org?.config?.mail?.on_end_voting);
            isMounted && setStartRegActiveEmail(org?.config?.mail?.on_start_registration);
            isMounted && setEndRegActiveEmail(org?.config?.mail?.on_end_registration);
            const notificationArrEmail = [];
            org?.config?.mail?.after_start_voting?.forEach(((value) => {
                const after = {
                    id: idGenerate(notificationArrEmail),
                    afterStart: true,
                    value: value
                }
                notificationArrEmail.push(after);
            }))
            org?.config?.mail?.to_end_voting?.forEach(((value) => {
                const before = {
                    id: idGenerate(notificationArrEmail),
                    afterStart: false,
                    value: value
                }
                notificationArrEmail.push(before);
            }))
            isMounted && setNotificationTimeEmail(notificationArrEmail);

            isMounted && setCreateVoteActiveSms(org?.config?.sms?.on_creating);
            isMounted && setStartVoteActiveSms(org?.config?.sms?.on_start_voting);
            isMounted && setEndVoteActiveSms(org?.config?.sms?.on_end_voting);
            isMounted && setStartRegActiveSms(org?.config?.sms?.on_start_registration);
            isMounted && setEndRegActiveSms(org?.config?.sms?.on_end_registration);
            const notificationArrSms = [];
            org?.config?.sms?.after_start_voting?.forEach(((value) => {
                const after = {
                    id: idGenerate(notificationArrSms),
                    afterStart: true,
                    value: value
                }
                notificationArrSms.push(after);
            }))
            org?.config?.sms?.to_end_voting?.forEach(((value) => {
                const before = {
                    id: idGenerate(notificationArrSms),
                    afterStart: false,
                    value: value
                }
                notificationArrSms.push(before);
            }))

            isMounted && setNotificationTimeSms(notificationArrSms);

        return () => {
            isMounted = false;
        }
    },
        [
            org.config.mail.on_creating,
            org.config.mail.on_start_voting,
            org.config.mail.on_end_voting,
            org.config.mail.on_start_registration,
            org.config.mail.on_end_registration,
            org.config.mail.after_start_voting,
            org.config.mail.to_end_voting,
            org.config.sms.on_creating,
            org.config.sms.on_start_voting,
            org.config.sms.on_end_voting,
            org.config.sms.on_start_registration,
            org.config.sms.on_end_registration,
            org.config.sms.after_start_voting,
            org.config.sms.to_end_voting
        ]
    )
//----Код для рассылки по email----------------------------------------------------------------------------------------------------------------//
    function handleCreateVoteActiveEmail() {
        if (isCreateVoteActiveEmail) {
            setSaveButtonActive(true);
            setCreateVoteActiveEmail(false);
        } else {
            setSaveButtonActive(true);
            setCreateVoteActiveEmail(true);
        }
    }

    function handleStartVoteActive() {
        if (isStartVoteActiveEmail) {
            setSaveButtonActive(true);
            setStartVoteActiveEmail(false);
        } else {
            setSaveButtonActive(true);
            setStartVoteActiveEmail(true);
        }
    }

    function handleEndVoteActive() {
        if (isEndVoteActiveEmail) {
            setSaveButtonActive(true);
            setEndVoteActiveEmail(false);
        } else {
            setSaveButtonActive(true);
            setEndVoteActiveEmail(true);
        }
    }

    function handleStartRegActive() {
        if (isStartRegActiveEmail) {
            setSaveButtonActive(true);
            setStartRegActiveEmail(false);
        } else {
            setSaveButtonActive(true);
            setStartRegActiveEmail(true);
        }
    }

    function handleEndRegActive() {
        if (isEndRegActiveEmail) {
            setSaveButtonActive(true);
            setEndRegActiveEmail(false);
        } else {
            setSaveButtonActive(true);
            setEndRegActiveEmail(true);
        }
    }

    function notificationInputChange(e, id) {
        const foundedEl = notificationTimeEmail.find(el => el.id === id);
        const filteredArray = notificationTimeEmail.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeEmail(filteredArray);
    }

    function handleOpenOptions(id) {
        if (id === isActiveSelectedAlertVoteEmail) {
            setActiveSelectedAlertVoteEmail(null)
        } else {
            setActiveSelectedAlertVoteEmail(id)
        }
    }

    function selectAfterStartNotification(id) {
        const foundedEl = notificationTimeEmail.find(el => el.id === id);
        const filteredArray = notificationTimeEmail.filter(el => el.id !== id);
        foundedEl.afterStart = true;
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeEmail(filteredArray);
        handleOpenOptions(id)
    }

    function selectBeforeEndNotification(id) {
        const foundedEl = notificationTimeEmail.find(el => el.id === id);
        const filteredArray = notificationTimeEmail.filter(el => el.id !== id);
        foundedEl.afterStart = false;
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeEmail(filteredArray);
        handleOpenOptions(id)
    }

    function addNotification() {
        const notification = {
            id: idGenerate(notificationTimeEmail),
            afterStart: true,
            value: 15
        }
        setNotificationTimeEmail([...notificationTimeEmail, notification]);
    }

    function removeNotification(id) {
        const filteredArray = notificationTimeEmail.filter(el => el.id !== id);
        setNotificationTimeEmail(filteredArray);
        setSaveButtonActive(true);
    }
//----Код для рассылки по sms----------------------------------------------------------------------------------------------------------------//
    function handleCreateVoteActiveSms() {
        if (isCreateVoteActiveSms) {
            setSaveButtonActive(true);
            setCreateVoteActiveSms(false);
        } else {
            setSaveButtonActive(true);
            setCreateVoteActiveSms(true);
        }
    }

    function handleStartVoteActiveSms() {
        if (isStartVoteActiveSms) {
            setSaveButtonActive(true);
            setStartVoteActiveSms(false);
        } else {
            setSaveButtonActive(true);
            setStartVoteActiveSms(true);
        }
    }

    function handleEndVoteActiveSms() {
        if (isEndVoteActiveSms) {
            setSaveButtonActive(true);
            setEndVoteActiveSms(false);
        } else {
            setSaveButtonActive(true);
            setEndVoteActiveSms(true);
        }
    }

    function handleStartRegActiveSms() {
        if (isStartRegActiveSms) {
            setSaveButtonActive(true);
            setStartRegActiveSms(false);
        } else {
            setSaveButtonActive(true);
            setStartRegActiveSms(true);
        }
    }

    function handleEndRegActiveSms() {
        if (isEndRegActiveSms) {
            setSaveButtonActive(true);
            setEndRegActiveSms(false);
        } else {
            setSaveButtonActive(true);
            setEndRegActiveSms(true);
        }
    }

    function notificationInputChangeSms(e, id) {
        const foundedEl = notificationTimeSms.find(el => el.id === id);
        const filteredArray = notificationTimeSms.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeSms(filteredArray);
    }

    function handleOpenOptionsSms(id) {
        if (id === isActiveSelectedAlertVoteSms) {
            setActiveSelectedAlertVoteSms(null)
        } else {
            setActiveSelectedAlertVoteSms(id)
        }
    }

    function selectAfterStartNotificationSms(id) {
        const foundedEl = notificationTimeSms.find(el => el.id === id);
        const filteredArray = notificationTimeSms.filter(el => el.id !== id);
        foundedEl.afterStart = true;
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeSms(filteredArray);
        handleOpenOptions(id)
    }

    function selectBeforeEndNotificationSms(id) {
        const foundedEl = notificationTimeSms.find(el => el.id === id);
        const filteredArray = notificationTimeSms.filter(el => el.id !== id);
        foundedEl.afterStart = false;
        filteredArray.push(foundedEl);
        setSaveButtonActive(true);
        setNotificationTimeSms(filteredArray);
        handleOpenOptions(id)
    }

    function addNotificationSms() {
        const notification = {
            id: idGenerate(notificationTimeSms),
            afterStart: true,
            value: 15
        }
        setNotificationTimeSms([...notificationTimeSms, notification]);
    }

    function removeNotificationSms(id) {
        const filteredArray = notificationTimeSms.filter(el => el.id !== id);
        setNotificationTimeSms(filteredArray);
        setSaveButtonActive(true);
    }

//------------------------------------------------------------------------------------------------------------------------------------------//
    function saveChanges() {
        setSaveButtonLoading(true);
        const afterStartNotificationsEmail = [];
        const beforeEndNotificationsEmail = [];
        const afterStartNotificationSms = [];
        const beforeEndNotificationsSms = [];
        notificationTimeEmail.forEach((notification) => {
            if (notification.afterStart) {
                if (notification.value !== '') {
                    afterStartNotificationsEmail.push(Number(notification.value));
                }
            } else {
                if (notification.value !== '') {
                    beforeEndNotificationsEmail.push(Number(notification.value));
                }
            }
        })

        notificationTimeSms.forEach((notification) => {
            if (notification.afterStart) {
                if (notification.value !== '') {
                    afterStartNotificationSms.push(Number(notification.value));
                }
            } else {
                if (notification.value !== '') {
                    beforeEndNotificationsSms.push(Number(notification.value));
                }
            }
        })
        const afterStartMail = afterStartNotificationsEmail.filter(function (item, pos) {
            return afterStartNotificationsEmail.indexOf(item) === pos;
        })
        const beforeEndMail = beforeEndNotificationsEmail.filter(function (item, pos) {
            return beforeEndNotificationsEmail.indexOf(item) === pos;
        })
        const afterStartSms = afterStartNotificationSms.filter(function (item, pos) {
            return afterStartNotificationSms.indexOf(item) === pos;
        })
        const beforeEndSms = beforeEndNotificationsSms.filter(function (item, pos) {
            return beforeEndNotificationsSms.indexOf(item) === pos;
        })

        const body = {
            mail: {
                enabled: true,
                on_creating: isCreateVoteActiveEmail,
                on_start_registration: isStartRegActiveEmail,
                on_end_registration: isEndRegActiveEmail,
                on_start_voting: isStartVoteActiveEmail,
                on_end_voting: isEndVoteActiveEmail,
                after_start_voting: afterStartMail,
                to_end_voting: beforeEndMail
            },
            sms: {
                sms_enabled: true,
                on_creating: isCreateVoteActiveSms,
                on_start_registration: isStartRegActiveSms,
                on_end_registration: isEndRegActiveSms,
                on_start_voting: isStartVoteActiveSms,
                on_end_voting: isEndVoteActiveSms,
                after_start_voting: afterStartSms,
                to_end_voting: beforeEndSms
            },
            org_id: org.id
        }
        requestHelper(Organizations.updateMailingSettings, body)
            .then(() => {
                reloadOrgPage();
                setSaveButtonLoading(false);
                setSaveButtonActive(false);
            })
            .catch((err) => {
                setSaveButtonLoading(false);
                setTextMessage('')
                setSaveButtonActive(false);
                throw new Error(err.message);
            })
    }

    return (
            <div className="mailing-settings">
                <h2 className="mailing-settings__heading">{constants.ORG_SETTINGS.MAILING_SETTINGS}</h2>
                <MailingSettingsForMail
                    isOrgSuperAdmin={isOrgSuperAdmin}
                    constants={constants}
                    handleCreateVoteActiveEmail={handleCreateVoteActiveEmail}
                    isCreateVoteActive={isCreateVoteActiveEmail}
                    handleStartVoteActive={handleStartVoteActive}
                    isStartVoteActiveEmail={isStartVoteActiveEmail}
                    handleEndVoteActive={handleEndVoteActive}
                    isEndVoteActiveEmail={isEndVoteActiveEmail}
                    handleStartRegActive={handleStartRegActive}
                    isStartRegActiveEmail={isStartRegActiveEmail}
                    handleEndRegActive={handleEndRegActive}
                    isEndRegActiveEmail={isEndRegActiveEmail}
                    notificationTimeEmail={notificationTimeEmail}
                    notificationInputChange={notificationInputChange}
                    handleOpenOptions={handleOpenOptions}
                    isActiveSelectedAlertVoteEmail={isActiveSelectedAlertVoteEmail}
                    selectAfterStartNotification={selectAfterStartNotification}
                    selectBeforeEndNotification={selectBeforeEndNotification}
                    removeNotification={removeNotification}
                    addNotification={addNotification}
                />
                <MailingSettingsForPhone
                    isOrgSuperAdmin={isOrgSuperAdmin}
                    constants={constants}
                    handleCreateVoteActive={handleCreateVoteActiveSms}
                    isCreateVoteActive={isCreateVoteActiveSms}
                    handleStartVoteActive={handleStartVoteActiveSms}
                    isStartVoteActive={isStartVoteActiveSms}
                    handleEndVoteActive={handleEndVoteActiveSms}
                    isEndVoteActive={isEndVoteActiveSms}
                    handleStartRegActive={handleStartRegActiveSms}
                    isStartRegActive={isStartRegActiveSms}
                    handleEndRegActive={handleEndRegActiveSms}
                    isEndRegActive={isEndRegActiveSms}
                    notificationTime={notificationTimeSms}
                    notificationInputChange={notificationInputChangeSms}
                    handleOpenOptions={handleOpenOptionsSms}
                    isActiveSelectedAlertVote={isActiveSelectedAlertVoteSms}
                    selectAfterStartNotification={selectAfterStartNotificationSms}
                    selectBeforeEndNotification={selectBeforeEndNotificationSms}
                    removeNotification={removeNotificationSms}
                    addNotification={addNotificationSms}
                />
                {isSaveButtonActive &&
                    <div className="mailing-settings__save-changes-button-block">
                        <button className={isSaveButtonLoading ? "mailing-settings__save-changes-button active" : "mailing-settings__save-changes-button"} onClick={saveChanges}>
                            {isSaveButtonLoading ? `${constants.ORG_SETTINGS.BUTTON_LOADING}` : `${constants.ORG_SETTINGS.BUTTON_SAVE_ADMINS_CHANGE}`}
                        </button>
                        <p className="mailing-settings__save-changes-button-text">{isTextMessage}</p>
                    </div>}
            </div>
    )
}
export default MailingSettings;
