import React, {useEffect, useState} from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import row_input_select_role from "../../img/Auth_icon_row_select_role.svg";
import AddUsers from "../AddUsers/AddUsers";
import * as Organizations from "../../Api/Organizations";
import * as Groups from "../../Api/Groups";
import {useNavigate} from "react-router-dom";
import {Validation} from "../../utils/Validation/Validation";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import {useOnClickOutsideModal} from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";

const AddNewGroupUsers = (props) => {

    const {
        constants,
        requestHelper
    } = props;

    const { runUpdateDataEvents } = useCurrentUserContext();
    const groupNameInput = Validation();
    const navigate = useNavigate();
    const [activeSelectedOrgForm, setActiveSelectedOrgForm] = useState(false);
    const [hideNextButton, setHideNextButton] = useState(false);
    const [hideSelectOrg, setHideSelectOrg] = useState(true);
    const [usersToAdd, setUsersToAdd] = useState([]);
    const [orgList, setOrgList] = useState([]);
    const [activeSelectOrg, setActiveSelectOrg] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState({});
    const [selectedOrgTitle, setSelectedOrgTitle] = useState(constants.ADD_NEW_VOTE.SELECT_ORG);
    const [saveOrgErrorMessage, setSaveOrgErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false)
    useOnClickOutsideModal(activeSelectOrg, () => setActiveSelectOrg(false));

    function changeUsersToAddArr(users) {
        setUsersToAdd(users);
    }

    useEffect(() => {
        let isMounted = true;
        if (orgList.length !== 1) {
            return;
        } else {
            isMounted && selectOrg(orgList[0]);
        }
        return () => {
            isMounted = false;
        }
    },[orgList])

    useEffect(() => {
        let isMounted = true;
        requestHelper(Organizations.getUserOrganizations)
            .then((data) => {
                isMounted && setOrgList(data);
            })
            .catch((err) => {
                throw new Error(err.message);
            })
        return () => {
            isMounted = false;
        }
    }, [requestHelper])

    function showSelectOrgForm() {
        setHideSelectOrg(false)
        setActiveSelectedOrgForm(true)
    }

    function selectOrg(org) {
        setSelectedOrgTitle(org.title);
        setSelectedOrg(org);
        setHideNextButton(true);
    }

    function sendRequest() {
        const filtredArray = usersToAdd.map((el => el.id))
        const body = {
            group_title: groupNameInput.value,
            users: filtredArray,
            org_id: selectedOrg.id
        }
        setLoading(true)
        requestHelper(Groups.addNewGroup, body)
            .then((data) => {
                if (data.status === 'ok') {
                    navigate('/group-users');
                    runUpdateDataEvents();
                    setSaveOrgErrorMessage('');
                } else {
                    setSaveOrgErrorMessage(constants.ADD_NEW_ORG.ADD_NEW_ORG_FAILURE_ERROR);
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setLoading(false)
                runUpdateDataEvents();
            })
    }

    function onSaveButtonClick() {
        if (groupNameInput.value === '') {
            setSaveOrgErrorMessage(constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_SAVE_ERROR);
        } else if (usersToAdd.length === 0) {
            setSaveOrgErrorMessage(constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS);
        } else {
            sendRequest();
        }
    }

    return (
        <div className="container__add-new-group-users _container">
            <GeneralTitleAllPages
                firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
                secondLetter={constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_GROUP_USERS}
                titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_ADD_GROUP_USERS }
            />
            <p className={activeSelectedOrgForm ? "add-new-group-users__current-name-org active" : "add-new-group-users__current-name-org"}>{selectedOrg?.title}</p>
            {
                hideSelectOrg && (
                    <div className="add-new-group-users__select-organization-block">
                        <h3 className="select-organization-block__title-select-org">{constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_USERS_SELECT_ORG}</h3>
                        <div className="select-organization-block__select-role">
                            <label className="select-organization-block__label">{constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_USERS_ORG}
                                <span className="select-organization-block__red-star"> *</span>
                            </label>
                            <div onClick={() => setActiveSelectOrg(!activeSelectOrg)} className="add-new-vote__time-zone-select-container">
                                <p className="add-new-vote__time-zone-select-value">{selectedOrgTitle}</p>
                                <img className="add-new-vote__time-zone-select-arrow" src={row_input_select_role} alt={constants.GENERAL.ALT_ICON} />
                                <div className={activeSelectOrg ? "add-new-vote__time-zone-options-container" : "add-new-vote__time-zone-options-container hidden"}>
                                    {orgList.map((org) => (
                                        <div className={`add-new-vote__time-zone-option-container ${org.settings.inactive && 'add-new-vote__time-zone-option-container_disabler'}`} key={org.id}>
                                            {org.settings.inactive ? (
                                                <p className="add-new-vote__time-zone-option">{org.title}</p>
                                            ) : (
                                                <p className="add-new-vote__time-zone-option" onClick={() => selectOrg(org)}>{org.title}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {hideNextButton && <div className="select-organization-block__button-block">
                            <button onClick={showSelectOrgForm} className="select-organization-block__button-next">{constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_NEXT_BTN}</button>
                        </div>}
                    </div>
                )}
            {
                activeSelectedOrgForm && (
                    <div className="add-new-group-users">
                        <div className="add-new-organization__name-org-input">
                            <label className="name-org-input__label-name-org">{constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_USERS_NAME_GROUP_USERS}
                                <span className="name-org-input__red-star">*</span></label>
                            <input className="name-org-input__field"
                                   type="text"
                                   value={groupNameInput.value}
                                   onChange={groupNameInput.onChange}
                                   placeholder={constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_USERS_PLACEHOLDER_NAME}/>
                        </div>
                        <div className="add-new-organization__top-pagination">
                            {/*<PaginationBlock constants={constants}/>*/}
                        </div>
                        <AddUsers
                            constants={constants}
                            requestHelper={requestHelper}
                            changeUsersToAddArr={changeUsersToAddArr}
                            usersToAdd={usersToAdd}
                        />
                        <div className="add-new-organization__bottom-pagination">
                            {/*<PaginationBlock constants={constants}/>*/}
                        </div>
                        <div className="add-new-organization__save-button">
                            <p className="add-new-organization__error-message">{saveOrgErrorMessage}</p>
                            <button onClick={onSaveButtonClick} className={isLoading ? "save-button__add-new-org-save-btn active" : "save-button__add-new-org-save-btn"}>{isLoading ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.ADD_NEW_GROUP_USERS.ADD_NEW_GROUP_USERS_SAVE_BTN}`}</button>
                        </div>
                    </div>
                )}
        </div>
    )}
export default AddNewGroupUsers;