import React, {useEffect, useState} from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import iconDeleteBtn from "../../img/AddNewOrgDeleteIcon.svg";
import iconEditBtn from "../../img/OrganizationsLisеIconEditButton.svg";
import iconLockRed from "../../img/AddNewOrgLockIcon.svg";
import iconLockBlack from "../../img/GroupUsersIconLock.svg";
import iconUpDownSort from "../../img/upDownSortIcon.svg";
import iconMembers from "../../img/GroupUsersIconMembers.svg";
import iconEye from "../../img/ListUsersIconEye.svg";
import GroupUsersDeleteModalGroup from "../GroupUsersDeleteModalGroup/GroupUsersDeleteModalGroup";
import * as Groups from "../../Api/Groups";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import {Validation} from "../../utils/Validation/Validation";
import {onSetCountPageForSearch} from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const GroupUsers = (props) => {

    const {
        constants,
        authAs,
        allGroups,
        onHandleGroupClick,
        requestHelper
    } = props;

    const { runUpdateDataEvents } = useCurrentUserContext();
    const [activeModal, setActiveModal] = useState(false);
    const [isSelectedGroupsForDelete, setSelectedGroupsForDelete] = useState([]);
    const [isSortToggle, setSortToggle] = useState(false);
    const [isAllCheckboxes, setAllCheckboxes] = useState(false);
    const usersSearch = Validation();
    const [usersForRender, setUsersForRender] = useState([]);
    const [usersSearchInput, setUsersSearchInput] = useState('');
    const [showResultsFrom, setShowResultsFrom] = useState(0);
    const [resultsShow, setResultsShow] = useState(5);
    const [result, setResult] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [selectedResultsShow, setSelectedResultsShow] = useState(5);

    useEffect(() => {
        let isMounted = true;
        if (allGroups.length !== 0) {
            isMounted && setSelectedGroupsForDelete(allGroups.map(function (item) {
                return {...item, isChecked: false}
            }))
        }
        return () => {
            isMounted = false;
        }
    },[allGroups])

    const closeModal = () => {
        setSelectedGroupsForDelete(isSelectedGroupsForDelete.map(function (item) {
            return {...item, isChecked: false}
        }))
        setAllCheckboxes(false);
        setActiveModal(false)
    }

    const onChangeTypeSort = (typeSort) => {
        if(typeSort) {
            usersForRender.sort(function (a, b){
                const emailA = a?.group_title?.toLowerCase()
                const emailB = b?.group_title?.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortToggle(false);
        } else {
            usersForRender.sort(function (a, b){
                const emailA = b?.group_title?.toLowerCase()
                const emailB = a?.group_title?.toLowerCase()
                if (emailA < emailB)
                    return -1
                if (emailA > emailB)
                    return 1
                return 0
            })
            setSortToggle(true);
        }
    }

    const onHandleSelectAllGroups = () => {
        const toggleChecked = (el) => el.isChecked === false;
        if (isSelectedGroupsForDelete.some(toggleChecked)) {
            setSelectedGroupsForDelete(isSelectedGroupsForDelete.map(function (item) {
                return {...item, isChecked: true}
            }))
            setAllCheckboxes(true);
        } else {
            setSelectedGroupsForDelete(isSelectedGroupsForDelete.map(function (item) {
                return {...item, isChecked: false}
            }))
            setAllCheckboxes(false);
        }
    }

    const onHandleCheckTable = (id) => {
        const currentGroup = isSelectedGroupsForDelete.find(el => el.id === id)
        if (currentGroup.isChecked === false) {
            const changedUser = {...currentGroup, isChecked: true}
            const updatedUsersTable = isSelectedGroupsForDelete.map(el => el.id === changedUser.id ? changedUser : el)
            setSelectedGroupsForDelete(updatedUsersTable)
        } else if (currentGroup.isChecked === true) {
            const changedUser = {...currentGroup, isChecked: false}
            const updatedUsersTable = isSelectedGroupsForDelete.map(el => el.id === changedUser.id ? changedUser : el)
            setSelectedGroupsForDelete(updatedUsersTable)
        }
        setAllCheckboxes(false);
    }

    const onRemoveGroupsClick = () => {
        const filteredIsCheckedArr = isSelectedGroupsForDelete.filter(el => el.isChecked === true)
        const groupsToDelete = filteredIsCheckedArr.map(el => el.id)
        const filteredGroupsNotDelete = isSelectedGroupsForDelete.filter(el => el.isChecked === false);
        setSelectedGroupsForDelete(filteredGroupsNotDelete);
        requestHelper(Groups.deleteGroups, groupsToDelete)
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res.status);
                } else {
                    runUpdateDataEvents();
                    console.log(res.text);
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                runUpdateDataEvents()
            })
        setActiveModal(false)
    }

    function handleShowResultsFrom(value) {
        setShowResultsFrom(value);
    }

    function handleResultsShow(value) {
        setResultsShow(value);
    }

    function showPrevResults() {
        if (resultsShow <= result) {
            return resultsShow
        } else {
            setShowResultsFrom(showResultsFrom - result);
            handleShowResultsFrom(showResultsFrom - result);
            setResultsShow(resultsShow - result);
            handleResultsShow(resultsShow - result);
            setPageCount(pageCount - 1);
        }
    }

    function showNextResults() {
        if (resultsShow >= usersForRender.length) {
            return resultsShow
        } else {
            setShowResultsFrom(0 + resultsShow);
            handleShowResultsFrom(0 + resultsShow);
            setResultsShow(result + resultsShow);
            handleResultsShow(result + resultsShow);
            setPageCount(pageCount + 1);
        }
    }

    function onChoiceClick(value) {
        setResultsShow(value);
        handleResultsShow(value);
        setResult(value);
        setSelectedResultsShow(value);
        setShowResultsFrom(0);
        handleShowResultsFrom(0);
        setPageCount(1);
    }

    function searchInput(value) {
        setUsersSearchInput(value);
    }

    useEffect(() => {
        let isMounted = true;
            if (usersSearchInput === '') {
                isMounted && setUsersForRender(isSelectedGroupsForDelete.sort(function (a, b){
                    const emailA = a?.group_title?.toLowerCase()
                    const emailB = b?.group_title?.toLowerCase()
                    if (emailA < emailB)
                        return -1
                    if (emailA > emailB)
                        return 1
                    return 0
                }));
                isMounted && onChoiceClick(5);
            } else {
                const dataForRender = [];
                isSelectedGroupsForDelete.forEach((group) => {
                    if (group?.group_title?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(group);
                    } else if (group?.owner?.title?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                        isMounted && dataForRender.push(group);
                    }
                })
                isMounted && onSetCountPageForSearch(dataForRender.length, onChoiceClick)
                isMounted && setUsersForRender(dataForRender);
            }
            return () => {
                isMounted = false;
            }
        }, [usersSearchInput, isSelectedGroupsForDelete]);

    return (
        <div className="container__group-users _container">
            <GeneralTitleAllPages
                    titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_GROUP_USER}
                    firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
                    secondLetter={constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_GROUP_USERS}/>
                {/*<button className="group-user__add-user-btn-mobile">{constants.GROUP_USERS.GROUP_USERS_ADD_USER_BTN_MOBILE}</button>*/}
                    <div className="group-users__sorting-pagination">
                        <div className="group-users__pagination">
                            <PaginationBlock
                                sortList={usersForRender}
                                search={usersSearch}
                                searchInput={searchInput}
                                onChoiceClick={onChoiceClick}
                                selectedResultsShow={selectedResultsShow}
                                pageCount={pageCount}
                                showPrevResults={showPrevResults}
                                showNextResults={showNextResults}
                                constants={constants}
                            />
                        </div>
                    {/*<p className="group-users__select-btn-mobile">{constants.GROUP_USERS.GROUP_USERS_SELECT_BTN_SELECT}</p>*/}
                    </div>
                        <div className="group-users__table-group-users">
                            <div className="table-group-users__header-table-group-users">
                                {authAs === 'admin' && activeModal === true ?
                                    <label className='table-group-users__checkbox_container'>
                                        <input
                                            onClick={onHandleSelectAllGroups}
                                            checked={isAllCheckboxes}
                                            type="checkbox"
                                            readOnly
                                        />
                                        <span className='table-group-users__checkmark'/>
                                    </label> : null}
                                <p className="header-table-group-users__name-group">{constants.GROUP_USERS.GROUP_USERS_NAME_GROUP_USERS}
                                    <img onClick={() => onChangeTypeSort(isSortToggle)} className="header-table-group-users__name-group-icon-sort" src={iconUpDownSort} alt={constants.GENERAL.ALT_ICON} /></p>
                                <p className="header-table-group-users__members">{constants.GROUP_USERS.GROUP_USERS_MEMBERS}</p>
                                <p className="header-table-group-users__actions">{constants.GROUP_USERS.GROUP_USERS_ACTIONS}</p>
                            </div>
                            {usersForRender.length !== 0 ? (
                                <>
                                {usersForRender.slice(showResultsFrom, resultsShow).map((el) => (
                                            <div key={el?.id} className="table-group-users-row">
                                                {authAs === 'admin' && activeModal === true ?
                                                    <label className='table-group-users__checkbox_container _hidden-check'>
                                                        <input
                                                            onClick={() => onHandleCheckTable(el?.id)}
                                                            type="checkbox"
                                                            checked={el?.isChecked}
                                                            readOnly
                                                        />
                                                        <span className='table-group-users__checkmark'/>
                                                    </label> : null}
                                                <div className="table-group-users-row__name-group-name-org">
                                                    <div className="table-group-users-row__checkbox-title">
                                                        {authAs === 'admin' && activeModal === true ?
                                                            <label className='table-group-users__checkbox_container _show-check '>
                                                                <input
                                                                    onClick={() => onHandleCheckTable(el?.id)}
                                                                    type="checkbox"
                                                                    checked={el?.isChecked}
                                                                    readOnly
                                                                />
                                                                <span className='table-group-users__checkmark'/>
                                                            </label> : null}
                                                        <p onClick={() => onHandleGroupClick(el?.id)} className="table-group-users-row__name-group">{el?.group_title}</p>
                                                    </div>
                                                        {/*<img className="table-group-users-row__icon-red-lock" src={iconLockRed} alt={iconLockRed}/>*/}
                                                    <p className="table-group-users-row__name-org">{el?.owner?.title}</p>
                                                </div>
                                                <div className="table-group-users-row__members">
                                                    <p className="table-group-users-row__members-mobile">{constants.GROUP_USERS.GROUP_USERS_MEMBERS}</p>
                                                    <div className="table-group-users-row__members-mobile-block">
                                                        <img className="table-group-users-row__members-icon" src={iconMembers} alt={iconMembers} />
                                                        <p className="table-group-users-row__members-numbers">{el?.member_count}</p>
                                                    </div>
                                                </div>
                                                <div className="table-group-users-row__buttons-block">
                                                    {authAs === 'superAdmin' ?
                                                        <div  onClick={() => onHandleGroupClick(el?.id)} className="table-group-users-row__show-group-btn">
                                                            <img className="table-group-users-row__show-group-icon" src={iconEye} alt={''} />
                                                            <p className="table-group-users-row__show-group-label">{constants.GROUP_USERS.GROUP_USERS_SHOW_GROUP}</p>
                                                        </div> : null}
                                                    {authAs === 'admin' ?
                                                        <div onClick={() => onHandleGroupClick(el?.id)} className="table-group-users-row__edit-btn">
                                                            <img className="table-group-users-row__icon-edit" src={iconEditBtn} alt={iconEditBtn}/>
                                                            <p className="table-group-users-row__label-edit-btn">{constants.GROUP_USERS.GROUP_USERS_EDIT_BTN}</p>
                                                        </div> : null}
                                                    {authAs === 'admin' ?
                                                        <div onClick={() => setActiveModal(true)} className="table-group-users-row__delete-btn">
                                                            <img className="table-group-users-row__icon-delete" src={iconDeleteBtn} alt={iconDeleteBtn}/>
                                                            <p className="table-group-users-row__label-icon-delete">{constants.GROUP_USERS.GROUP_USERS_DELETE_BTN}</p>
                                                        </div> : null}
                                                    <div className="table-group-users-row__block-unblock-btn">
                                                        {/*<img className="table-group-users-row__icon-black-lock" src={iconLockBlack} alt={iconLockBlack}/>*/}
                                                        {/*<p className="table-group-users-row__label-block-unblock-btn">{constants.GROUP_USERS.GROUP_USERS_BLOCK_BTN}</p>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </>
                            ) : (
                                <div className="list-users-row__message-empty">
                                    <p className="table-list-users__no-users">{constants.GROUP_USERS.GROUP_USERS_EMPTY_TEXT_NO_GROUPS}<span className="name-org-input__red-star"> *</span></p>
                                </div>

                            )}
            </div>
            <div className="group-users__sorting-pagination">
                <div className="group-users__pagination">
                    <PaginationBlock
                        sortList={usersForRender}
                        search={usersSearch}
                        searchInput={searchInput}
                        onChoiceClick={onChoiceClick}
                        selectedResultsShow={selectedResultsShow}
                        pageCount={pageCount}
                        showPrevResults={showPrevResults}
                        showNextResults={showNextResults}
                        constants={constants}
                    />
                </div>
            </div>
            <GroupUsersDeleteModalGroup
                active={activeModal}
                closeModal={closeModal}
                constants={constants}
                isSelectedGroupsForDelete={isSelectedGroupsForDelete}
                onRemoveGroupsClick={onRemoveGroupsClick}
            />
        </div>
    )
}
export default GroupUsers;