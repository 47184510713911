import React from "react";
import DetailsVotesPageResultGridTable2 from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable2";

const ResultSingleTable = (props) => {
  const { question } = props;

  return (
    <div
      className="details-votes-page-result-question-grid"
      style={{ borderBottom: "none" }}
    >
      <DetailsVotesPageResultGridTable2
        answers={question.answers}
        question={question}
        onlyLastTable
      />
    </div>
  );
};
export default ResultSingleTable;
