import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import {BrowserRouter} from "react-router-dom";
import {CurrentUserContextProvider} from "./contexts/CurrentUserContext";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <CurrentUserContextProvider>
                <App/>
            </CurrentUserContextProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
