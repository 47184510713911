export const timeList = [
    {
        id: 1,
        value: 1,
        label: '1 час'
    },
    {
        id: 2,
        value: 2,
        label: '2 часа'
    },
    {
        id: 3,
        value: 4,
        label: '4 часа'
    },
    {
        id: 4,
        value: 8,
        label: '8 часов'
    },
    {
        id: 5,
        value: 12,
        label: '12 часов'

    },
    {
        id: 6,
        value: 24,
        label: '24 часа'
    }
]