//export const config = {
//    java_api_url: "https://rcsi.cryptoveche.ru/api",
//    client_url: "https://rcsi.cryptoveche.ru",
//    enable_esia: false,
//    ws_connect: "wss://rcsi.cryptoveche.ru/ws",
//    ws_pass: "client",
//    ws_user: "client"
//};

export const config = {
    java_api_url: "https://rfbr.cryptoveche.ru/api",
    client_url: "https://rfbr.cryptoveche.ru",
    enable_esia: false,
    ws_connect: "wss://rfbr.cryptoveche.ru/ws",
    ws_pass: "client",
    ws_user: "client"
};
