import {useEffect, useState} from "react";

function useStartVoteShowButton(currentEventData) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;

        if (currentEventData?.status === 'waiting') {
            isMount && setValue(true)
        } else if (currentEventData?.status === 'registration' && currentEventData?.isVoting === false) {
            isMount && setValue(true)
        } else if (currentEventData?.status === 'event waiting') {
            isMount && setValue(true)
        } else {
            isMount && setValue(false)
        }

        return () => {
            isMount = false;
        }
    },[currentEventData])

    return [value, setValue];
}

export default useStartVoteShowButton;