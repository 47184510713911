import React from "react";
import CardQuestionCellsTable from "./CardQuestionCellsTable/CardQuestionCellsTable";

const DetailsVotesPageResultVotesCardQuestionTable2 = (props) => {
  const { answers, numInvalid, question } = props;

  console.log("answers:", answers);
  console.log("question: ", question);

  return (
    <div className={"details-votes-page-result-card-table__wrapper"} style={{maxWidth: '100%'}}>
      <table className={"details-votes-page-result-card-table__table"} style={{width: '100%'}}>
        <tbody>
          <tr>
            <th>ID</th>
            <th>ФИО</th>
            <th>Выбор</th>
          </tr>
          {question.users?.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>
                {user.answers.map((answer, i) => (
                  <p key={answer.id} style={{marginBottom: 10}}>
                    {answers.find((it) => answer.id === it.id).title}
                  </p>
                ))}
              </td>
            </tr>
          ))}
          {/* <CardQuestionCellsTable
            varinantAnswer={"Недействительные бюллетени"}
            result={numInvalid}
          /> */}
        </tbody>
      </table>
    </div>
  );
};

export default DetailsVotesPageResultVotesCardQuestionTable2;
