export const setCorrectNameProtocol = (templateLink) => {
    if (templateLink !== undefined) {
        const protocolName = templateLink.split('/');
        if (templateLink !== '') {
            if (protocolName[3].includes('_')) {
                const name = protocolName[3].split('_');
                if (name[1].includes('--')) {
                    return name[1].replace(/--/g, ' ');
                } else {
                    return name[1];
                }
            } else {
                const name = protocolName[3];
                if (name.includes('--')) {
                    return name.replace(/--/g, ' ')
                } else {
                    return name;
                }
            }
        }
    } else {
        return 'https://files.dltc.xyz/TmpCommonProtocolTemplate.docx'
    }
}