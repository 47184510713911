import {useEffect, useState} from "react";

function useShowSignProtocolButton(currentEventData, currentUser) {

    const [value, setValue] = useState(false);

    const findUser = currentEventData?.protocol?.counters.find(el => el.id === currentUser.id)

    useEffect(() => {
        let isMount = true;

        if (currentUser?.authAs === "counter") {
            if (findUser?.signed === false) {
                isMount && setValue(true)
            } else if (findUser?.signed === undefined) {
                isMount && setValue(true)
            } else {
                isMount && setValue(false)
            }
        }

        return () => {
            isMount = false;
        }
    },[currentEventData, currentUser])

    return [value, setValue];
}
export default useShowSignProtocolButton;