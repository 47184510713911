import React from "react";

const GroupUsersDeleteModalSomeUsers = (props) => {

    const {
        closeModalDeleteSomeUsers,
        onRemoveSomeUsersClick,
        constants,
        currentGroupUsers
    } = props;

    const filtredIsChecked = currentGroupUsers.filter(el => el.isChecked === true)

    return (
        <div id={'block'} className='container__group-users-delete-modal-some-users'>
            <p className="group-users-delete-modal-some-users__selector-group">
                {constants.GROUP_USERS.GROUP_USERS_MODAL_MARKED_FOR_DELETION} {filtredIsChecked?.length}
            </p>
            <div className="group-users-delete-modal-some-users__buttons">
                <button onClick={closeModalDeleteSomeUsers} className="group-users-delete-modal-some-users__cancel-btn">{constants.GROUP_USERS.GROUP_USERS_MODAL_CANCEL}</button>
                <button onClick={onRemoveSomeUsersClick} className="group-users-delete-modal-some-users__delete-btn">{constants.GROUP_USERS.GROUP_USERS_MODAL_DELETE_SELECTED}</button>
            </div>
        </div>
    )
}
export default GroupUsersDeleteModalSomeUsers;