import React, {useEffect, useState} from "react";
import GeneralTitleAllPages from "../GeneralTitleAllPages/GeneralTitleAllPages";
import iconDate from "../../img/MyVotes_data_icon.svg";
import iconTime from "../../img/MyVotes_icon_time.svg";
import iconEditBtn from "../../img/OrganizationsLisеIconEditButton.svg";
import iconDeleteBtn from "../../img/AddNewGroupIconBasket.svg";
import PaginationBlockOrg from "../PaginationBlockOrg/PaginationBlockOrg";
import {Validation} from "../../utils/Validation/Validation";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import * as Template from "../../Api/Template";
import {onSetCountPageForSearch} from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const ListTemplates = (props) => {

    const {
        constants,
        handleSelectTemplate,
        allTemplates,
        requestHelper,
        formatDate,
        formatTime
    } = props;

    const { runUpdateDataEvents } = useCurrentUserContext();
    const templateDetailsSearch = Validation();
    const [templateDetailsForRender, setTemplateDetailsForRender] = useState([]);
    const [templateDetailsSearchInput, setTemplateDetailsSearchInput] = useState('');
    const [showResultsFrom, setShowResultsFrom] = useState(0);
    const [resultsShow, setResultsShow] = useState(5);
    const [result, setResult] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [selectedResultsShow, setSelectedResultsShow] = useState(5);

    const onHandleDeleteTemplate = (templateId) => {
        const body = [templateId]
        requestHelper(Template.deleteTemplate, body)
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res.status);
                } else {
                    runUpdateDataEvents();
                    console.log(res.text);
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                runUpdateDataEvents()
            })
    }

    function handleShowResultsFrom(value) {
        setShowResultsFrom(value);
    }

    function handleResultsShow(value) {
        setResultsShow(value);
    }

    function showPrevResults() {
        if (resultsShow <= result) {
            return
        } else {
            setShowResultsFrom(showResultsFrom - result);
            handleShowResultsFrom(showResultsFrom - result);
            setResultsShow(resultsShow - result);
            handleResultsShow(resultsShow - result);
            setPageCount(pageCount - 1);
        }
    }

    function showNextResults() {
        if (resultsShow >= templateDetailsForRender.length) {
            return
        } else {
            setShowResultsFrom(0 + resultsShow);
            handleShowResultsFrom(0 + resultsShow);
            setResultsShow(result + resultsShow);
            handleResultsShow(result + resultsShow);
            setPageCount(pageCount + 1);
        }
    }

    function onChoiceClick(value) {
        setResultsShow(value);
        handleResultsShow(value);
        setResult(value);
        setSelectedResultsShow(value);
        setShowResultsFrom(0);
        handleShowResultsFrom(0);
        setPageCount(1);
    }

    function searchInput(value) {
        setTemplateDetailsSearchInput(value);
    }

    useEffect(() => {
        let isMounted = true;
            if (templateDetailsSearchInput === '') {
                isMounted && onChoiceClick(5);
                isMounted && setTemplateDetailsForRender(allTemplates);
            } else {
                const dataForRender = [];
                allTemplates?.forEach((template) => {
                    if (template?.title?.toLowerCase().includes(templateDetailsSearchInput.toLowerCase())) {
                        dataForRender.push(template);
                    }
                })
                isMounted && onSetCountPageForSearch(dataForRender.length, onChoiceClick)
                isMounted && setTemplateDetailsForRender(dataForRender);
            }
            return () => {
                isMounted = false;
            }
        },
        [templateDetailsSearchInput, allTemplates]);

    return (
        <div className="list-templates__container _container">
            <GeneralTitleAllPages
                titleName={constants.GENERAL_TITLE.GENERAL_TITLE_TITLENAME_TEMPLATES_VOTE}
                firstLetter={constants.GENERAL_TITLE.GENERAL_TITLE_FIRTSLETTER}
                secondLetter={constants.GENERAL_TITLE.GENERAL_TITLE_SECONDLETTER_TEMPLATES_VOTE}
            />
            <button className="list-templates__add-template-btn-mobile">{constants.ADD_TEMPLATES.ADD_TEMPLATES_ADD_TEMPLATE_BTN}</button>
            <div className="list-templates__filter-sort-pagination">
                <PaginationBlockOrg
                    sortList={templateDetailsForRender}
                    search={templateDetailsSearch}
                    searchInput={searchInput}
                    onChoiceClick={onChoiceClick}
                    selectedResultsShow={selectedResultsShow}
                    pageCount={pageCount}
                    showPrevResults={showPrevResults}
                    showNextResults={showNextResults}
                    constants={constants}
                />
            </div>
            <div className="list-templates__main-block">
                <div className="list-templates__header-table-templates">
                    <p className="list-templates__header-name">{constants.ADD_TEMPLATES.ADD_TEMPLATES_NAME_TEMPLATE}</p>
                    <p className="list-templates__header-created-datetime">{constants.ADD_TEMPLATES.ADD_TEMPLATES_DATE_CREATE} (UTC+3)</p>
                    <p className="list-templates__header-actions">{constants.ADD_TEMPLATES.ADD_TEMPLATES_ACTION}</p>
                </div>
                {templateDetailsForRender?.length !== 0 ? (
                    <>
                        {templateDetailsForRender.sort(function (a, b) {
                            const emailA = a?.title?.toLowerCase(), emailB = b?.title?.toLowerCase()
                            if (emailA < emailB)
                                return -1
                            if (emailA > emailB)
                                return 1
                            return 0
                        }).slice(showResultsFrom, resultsShow).map((el) => (
                            <div key={el?.id} className="list-templates-row">
                                <div className="list-templates-row__name-templates-name-org">
                                    <p onClick={() => handleSelectTemplate(el)} className="list-templates-row__name-templates">{el?.title}</p>
                                    {/*<img className="table-group-users-row__icon-red-lock" src={iconLockRed} alt={iconLockRed}/>*/}
                                    <p className="list-templates-row__name-org">{el?.owner?.title}</p>
                                </div>
                                <div className="list-templates-row__created-datetime">
                                    <p className="list-templates-row__created-datetime-label-mobile">{constants.ADD_TEMPLATES.ADD_TEMPLATES_DATE_CREATE_MOBILE}</p>
                                    <div className="list-templates-row__date-icon-block">
                                        <img className="list-templates-row__icon-datetime" src={iconDate} alt={constants.GENERAL.ALT_ICON}/>
                                        <p>{el.created_date !== undefined ? formatDate(el?.created_date) : ''}</p>
                                    </div>
                                    <div className="list-templates-row__time-icon-block">
                                        <img className="list-templates-row__icon-time" src={iconTime} alt={constants.GENERAL.ALT_ICON}/>
                                        <p>{formatTime(el?.created_date)}</p>
                                    </div>
                                </div>
                                <div className="list-templates-row__buttons-block">
                                    <div onClick={() => handleSelectTemplate(el)} className="list-templates-row__edit-btn">
                                        <img className="list-templates-row__icon-edit" src={iconEditBtn} alt={constants.GENERAL.ALT_ICON}/>
                                        <p className="list-templates-row__label-edit-btn">{constants.GROUP_USERS.GROUP_USERS_EDIT_BTN}</p>
                                        <p className="list-templates-row__label-edit-btn-mobile">{constants.ADD_TEMPLATES.ADD_TEMPLATES_EDIT_MOBILE_BTN}</p>
                                    </div>
                                    <div onClick={() => onHandleDeleteTemplate(el?.id)} className="list-templates-row__delete-btn">
                                        <img className="list-templates-row__icon-delete" src={iconDeleteBtn} alt={constants.GENERAL.ALT_ICON}/>
                                        <p className="list-templates-row__label-icon-delete">{constants.GROUP_USERS.GROUP_USERS_DELETE_BTN}</p>
                                        <p className="list-templates-row__label-icon-delete-mobile">{constants.ADD_TEMPLATES.ADD_TEMPLATES_DELETE_MOBILE_BTN}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="list-users-row__message-empty">
                        <p className="table-list-users__no-users">{constants.ADD_TEMPLATES.ADD_TEMPLATES_NOT_FIND_MESSAGE}</p>
                    </div>
                )}

                {/*<div className="list-templates__show-more-btn-mobile">{constants.ADD_TEMPLATES.ADD_TEMPLATES_SHOW_MORE_BTN}</div>*/}
            </div>
            <div className="list-templates__filter-sort-pagination">
                <PaginationBlockOrg
                    sortList={templateDetailsForRender}
                    search={templateDetailsSearch}
                    searchInput={searchInput}
                    onChoiceClick={onChoiceClick}
                    selectedResultsShow={selectedResultsShow}
                    pageCount={pageCount}
                    showPrevResults={showPrevResults}
                    showNextResults={showNextResults}
                    constants={constants}
                />
            </div>
        </div>
    )
}
export default ListTemplates;