import React from "react";

const RadioCheckBoxIntervalType = (props) => {

    const {
        onHandleGetValueCheck,
        isActiveRadioCheck,
        value
    } = props;

    return (
        <input
            onChange={() => onHandleGetValueCheck(value)}
            className="details-vote-general-info-option-prolong-vote-modal__select-type-checkbox"
            type={"radio"}
            checked={value === isActiveRadioCheck}
        />
    )
}
export default RadioCheckBoxIntervalType;