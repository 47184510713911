import React from "react";

const CardQuestionCellsTable = (props) => {

    const {
        varinantAnswer,
        result
    } = props;

    return (
        <>
        <p></p>
            <tr>
                <td>{varinantAnswer}</td>
                <td>{result}</td>
            </tr>
        </>
    )
}

export default CardQuestionCellsTable;