import {useEffect, useState} from "react";

function useSectorCircleVote(registeredUsersPercentage) {

    const [value, setValue] = useState(0);

    useEffect(() => {
        let isMount = true;

        if (registeredUsersPercentage === 0) {
            isMount && setValue(2)
        } else if (registeredUsersPercentage === 100) {
            isMount && setValue(1)
        } else {
            isMount && setValue(Number(`0.${registeredUsersPercentage}`))
        }

        return () => {
            isMount = false;
        }
    },[registeredUsersPercentage])

    return [value, setValue];
}
export default useSectorCircleVote;