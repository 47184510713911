import {useEffect, useState} from "react";

function useShowProlongRadioButton(currentEventData, constants) {

    const typeIntervalProlongAll = [
        {
            id: 1,
            value: 'prolong_vote',
            label: constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_VOTE
        },
        {
            id: 2,
            value: 'prolong_reg',
            label: constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_REG
        },
        {
            id: 3,
            value: 'prolong',
            label: constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_REG_AND_VOTE
        },
    ]

    const typeIntervalProlongVote = [
        {
            id: 1,
            value: 'prolong_vote',
            label: constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_MODAL_VOTE
        }
    ]

    const [value, setValue] = useState([]);

    useEffect(() => {
        let isMount = true;

        if (currentEventData?.status === 'voting') {
            isMount && setValue(typeIntervalProlongVote)
        } else if (currentEventData?.status === 'event waiting') {
            isMount && setValue(typeIntervalProlongVote)
        } else {
            isMount && setValue(typeIntervalProlongAll)
        }

        return () => {
            isMount = false;
        }
    },[currentEventData, constants])

    return [value, setValue];
}
export default useShowProlongRadioButton;