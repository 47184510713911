import {useEffect, useState} from "react";

function useFindTimeZoneProfileUser(currentTimeZoneUser) {

    const [value, setValue] = useState({});

    const listTimeZone = [
        {
            LABEL: "(UTC-11) Самоa",
            VALUE: "-11"
        },
        {
            LABEL: "(UTC-10) США - Гавайи",
            VALUE: "-10"
        },
        {
            LABEL: "(UTC-9) США - Аляска",
            VALUE: "-9"
        },
        {
            LABEL: "(UTC-8) США - Невада",
            VALUE: "-8"
        },
        {
            LABEL: "(UTC-7) США - Айдахо",
            VALUE: "-7"
        },
        {
            LABEL: "(UTC-6) Центральноамериканское время",
            VALUE: "-6"
        },
        {
            LABEL: "(UTC-5) США - Флорида",
            VALUE: "-5"
        },
        {
            LABEL: "(UTC-4) Чили",
            VALUE: "-4"
        },
        {
            LABEL: "(UTC-3) Гренландия",
            VALUE: "-3"
        },
        {
            LABEL: "(UTC-2) Бразилия",
            VALUE: "-2"
        },
        {
            LABEL: "(UTC-1) Кабо-Верде",
            VALUE: "-1"
        },
        {
            LABEL: "(UTC+0) Великобритания - Лондон",
            VALUE: "0"
        },
        {
            LABEL: "(UTC+1) Центральноевропейское время",
            VALUE: "1"
        },
        {
            LABEL: "(UTC+2) Восточноевропейское время",
            VALUE: "2"
        },
        {
            LABEL: "(UTC+3) Россия - Москва",
            VALUE: "3"
        },
        {
            LABEL: "(UTC+4) Россия - Самара",
            VALUE: "4"
        },
        {
            LABEL: "(UTC+5) Россия - Екатеринбург",
            VALUE: "5"
        },
        {
            LABEL: "(UTC+6) Россия - Омск",
            VALUE: "6"
        },
        {
            LABEL: "(UTC+7) Россия - Красноярск",
            VALUE: "7"
        },
        {
            LABEL: "(UTC+8) Россия - Иркутск",
            VALUE: "8"
        },
        {
            LABEL: "(UTC+9) Россия - Якутск",
            VALUE: "9"
        },
        {
            LABEL: "(UTC+10) Россия - Владивосток",
            VALUE: "10"
        },
        {
            LABEL: "(UTC+11) Россия - Магадан",
            VALUE: "11"
        },
        {
            LABEL: "(UTC+12) Россия - Камчатка",
            VALUE: "12"
        },
        {
            LABEL: "(UTC+13) Самоа",
            VALUE: "13"
        },
        {
            LABEL: "(UTC+14) Кирибати (острова Лайн)",
            VALUE: "14"
        }
    ]

    useEffect(() => {
        let isMount = true;

        if (currentTimeZoneUser !== undefined) {
            const findTimeZone = listTimeZone.find((el => el.VALUE === currentTimeZoneUser))
            isMount && setValue(findTimeZone)
        }

        return () => {
            isMount = false;
        }
    },[currentTimeZoneUser])

    return [value, setValue];
}

export default useFindTimeZoneProfileUser;