import * as Organizations from "../../Api/Organizations";

export const orgSettingsGetStatisticsCurrentOrg = (requestHelper, body, setOrganizationStatistics) => {
    requestHelper(Organizations.getStatisticsCurrentOrganization, body)
        .then((data) => {
            if (data.status === 'failure') {
                setOrganizationStatistics([]);
            } else {
                setOrganizationStatistics(data);
            }
        })
        .catch((err) => {
            throw new Error(err.message);
        })
}