import {useEffect, useState} from "react";

function useShowOptionsProlongVoteButton(currentEventData) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;

        if (currentEventData?.config?.general?.prolong === true) {
            if(currentEventData?.status === 'ended') {
                isMount && setValue(false)
            } else if (currentEventData?.status === 'quorum_unpresant') {
                isMount && setValue(false)
            } else {
                isMount && setValue(true)
            }
        }

        return () => {
            isMount = false;
        }
    },[currentEventData])

    return [value, setValue];
}
export default useShowOptionsProlongVoteButton;