export const setConditionQuorum = (condition, constants) => {

    const conditionList = {
        '0' : `${constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION_NONE}`,
        '50' : `${constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION_HALF_MEMBERS}`,
        '51' : `${constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION_MORE_THAN_HALF_MEMBERS}`,
        '66' : `${constants.DETAILS_VOTE.STATISTIC_QUORUM_CONDITION_INVOLVEMENT}`
    }

    for (let key in conditionList) {
        if (condition === key) {
            return conditionList[key];
        }
    }

    return setConditionQuorum;
}