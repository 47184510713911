import React from "react";
import imageMainPage from "../../img/mainPageImage.svg";
import {useNavigate} from "react-router-dom";

function Main(props) {

    const {
        constants,
        isCommonStatistic
    } = props;

    const navigate = useNavigate();

    return (
        <div className="main _container">
            <div className="main-count-voters">
                <p className="main-count-voters__title">{constants.MAIN_PAGE.MAIN_PAGE_COUNT_VOTES_TITLE}</p>
                <p className="main-count-voters__all-count">{constants.MAIN_PAGE.MAIN_PAGE_ALL_COUNT}</p>
                <p className="main-count-voters__count">{isCommonStatistic?.eventsCount}</p>
            </div>
            <div className="main-redirect-votes-page">
                <div className="main-redirect-votes-page-link-vote">
                    <p className="main-redirect-votes-page-link-vote__text">{constants.MAIN_PAGE.MAIN_PAGE_LINK_VOTES_PAGE_TEXT}</p>
                    <div onClick={() => navigate('/list-votes')} className="main-redirect-votes-page-link-vote__link-btn">
                        <p>{constants.MAIN_PAGE.MAIN_PAGE_LINK_BTN}</p>
                    </div>
                </div>
                    <img className="main-redirect-votes-page-link-vote__image" src={imageMainPage} alt={constants.ALT_ICON}/>
            </div>
        </div>
    );
}
export default Main;
