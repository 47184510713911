import {useEffect, useState} from "react";

function useStartRegShowButton(currentEventData) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;

        if (currentEventData?.status === 'waiting') {
            if (currentEventData?.onButton === true) {
                isMount && setValue(false)
            } else if (currentEventData?.config?.event?.combined_time === true) {
                isMount && setValue(false)
            } else {
                isMount && setValue(true)
            }
        } else if (currentEventData?.status === 'registration' && currentEventData?.isRegistration === false) {
            isMount && setValue(true)
        } else {
            isMount && setValue(false)
        }

        return () => {
            isMount = false;
        }
    },[currentEventData])

    return [value, setValue];
}

export default useStartRegShowButton;