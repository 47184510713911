import React, {useEffect, useState} from "react";
import iconExcel from "../../img/AddNewOrgIconExcel.svg";
import iconDate from "../../img/DetailsVoteStatisitcsVoteIconDate.svg";
import iconTime from "../../img/VotesPageActiveVotes_time_icon.svg";
import iconReg from "../../img/AddNewOrgSuccessIcon.svg";
import iconNoReg from "../../img/DetailsVoteVotingIconNoReg.svg";
import iconVoted from "../../img/DetailsVoteVotingIconVoted.svg";
import iconNoVoted from "../../img/DetailsVoteVotingIconNoVote.svg";
import imageNoActiveVoting from "../../img/DetailsVoteObserversIconEmptyComponent.svg";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import {Link} from "react-router-dom";
import {setEmptyTextDetailVote} from "../../utils/SetEmptyTextDetailVote/setEmptyTextDetailVote";
import {Validation} from "../../utils/Validation/Validation";
import useSetTableListUsersDetailsVote
    from "../../utils/CustomHooks/useSetTableListUsersDetailsVote/useSetTableListUsersDetailsVote";
import * as Events from "../../Api/Events";
import {onSetCountPageForSearch} from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const DetailsVoteVotingObserversCounting = (props) => {

    const {
        constants,
        titleName,
        titleNameMobile,
        changeStatusColumn,
        listMembers,
        formatDate,
        formatTime,
        currentEventData,
        requestHelper,
        handleResetIsSelectedTemplateMode
    } = props;

    const [isTableUsers, setTableUsers] = useSetTableListUsersDetailsVote(listMembers?.users, constants, changeStatusColumn);
    const [showNoActiveVotingObservers, setShowNoActiveVotingObservers] = useState(false);
    const [hideBlock, setHideBlock] = useState(true);
    const usersSearch = Validation();
    const [usersForRender, setUsersForRender] = useState([]);
    const [usersSearchInput, setUsersSearchInput] = useState('');
    const [showResultsFrom, setShowResultsFrom] = useState(0);
    const [resultsShow, setResultsShow] = useState(5);
    const [result, setResult] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [selectedResultsShow, setSelectedResultsShow] = useState(5);
    const [isShowSendNotify, setShowSendNotify] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onHandleSendNotify = () => {
        setLoading(true)
        requestHelper(Events.getSendNotify, currentEventData?.id)
            .then((data) => {
                if (data.status === 'ok') {
                    console.log(data)
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let isMounted = true;
        if (listMembers?.users?.length === 0) {
            isMounted && setShowNoActiveVotingObservers(true)
            isMounted && setHideBlock(false)
        } else {
            isMounted && setShowNoActiveVotingObservers(false)
            isMounted && setHideBlock(true)
        }

       if ((changeStatusColumn === 'voting' && currentEventData?.status === 'ended')) {
           isMounted && setShowSendNotify(false)
        } else if (changeStatusColumn === 'voting' && currentEventData?.status === 'quorum_unpresant') {
           isMounted && setShowSendNotify(false)
       } else if (changeStatusColumn === 'observers') {
           isMounted && setShowSendNotify(false)
        } else if (changeStatusColumn === 'counting') {
           isMounted && setShowSendNotify(false)
       } else {
           isMounted && setShowSendNotify(true)
       }

       return () => {
           isMounted = false;
       }
    }, [changeStatusColumn, listMembers, currentEventData?.status])

    function handleShowResultsFrom(value) {
        setShowResultsFrom(value);
    }

    function handleResultsShow(value) {
        setResultsShow(value);
    }

    function showPrevResults() {
        if (resultsShow <= result) {
            return resultsShow
        } else {
            setShowResultsFrom(showResultsFrom - result);
            handleShowResultsFrom(showResultsFrom - result);
            setResultsShow(resultsShow - result);
            handleResultsShow(resultsShow - result);
            setPageCount(pageCount - 1);
        }
    }

    function showNextResults() {
        if (resultsShow >= usersForRender?.length) {
            return resultsShow
        } else {
            setShowResultsFrom(0 + resultsShow);
            handleShowResultsFrom(0 + resultsShow);
            setResultsShow(result + resultsShow);
            handleResultsShow(result + resultsShow);
            setPageCount(pageCount + 1);
        }
    }

    function onChoiceClick(value) {
        setResultsShow(value);
        handleResultsShow(value);
        setResult(value);
        setSelectedResultsShow(value);
        setShowResultsFrom(0);
        handleShowResultsFrom(0);
        setPageCount(1);
    }

    function searchInput(value) {
        setUsersSearchInput(value);
    }

    useEffect(() => {
        let isMounted = true;
        if (usersSearchInput === '') {
            isMounted && onChoiceClick(5);
            isMounted && setUsersForRender(isTableUsers);
        } else {
            const dataForRender = [];
            isTableUsers.forEach((user) => {
                if (user?.email?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                    isMounted && dataForRender.push(user);
                } else if (user?.last_name?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                    isMounted && dataForRender.push(user);
                } else if (user?.first_name?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                    isMounted && dataForRender.push(user);
                } else if (user?.second_name?.toLowerCase().includes(usersSearchInput.toLowerCase())) {
                    isMounted && dataForRender.push(user);
                }
            })
            isMounted && onSetCountPageForSearch(dataForRender.length, onChoiceClick)
            isMounted && setUsersForRender(dataForRender);
        }
        return () => {
            isMounted = false;
        }
    }, [usersSearchInput, isTableUsers]);

    return (
        <div className="details-vote-voting-observers-counting__container">
            <h3 className="details-vote-voting-observers-counting__title-mobile">{titleNameMobile}</h3>
            <div className="details-vote-voting-observers-counting__title-block">
                <h3 className="details-vote-voting-observers-counting__title">{titleName}</h3>
                {/*{hideBlock && (*/}
                {/*    <div className="details-vote-voting-observers-counting__export-excel">*/}
                {/*    <img className="details-vote-voting-observers-counting__export-excel-icon" src={iconExcel} alt={constants.GENERAL.ALT_ICON}/>*/}
                {/*    <p className="details-vote-voting-observers-counting__export-excel-label">ЭКСПОРТ В EXCEL</p>*/}
                {/*</div>*/}
                {/*)}*/}
            </div>
            <div className="details-vote-voting-observers-counting__last-update">
                <p className="details-vote-voting-observers-counting__last-update-label">{constants.DETAILS_VOTE.STATISTIC_LATEST_UPDATE}</p>
                <img src={iconDate} alt={constants.GENERAL.ALT_ICON}/>
                <p className="details-vote-voting-observers-counting__last-update-icon-label">{listMembers?.updatedTime !== undefined ? formatDate(listMembers?.updatedTime) : ''}</p>
                <img src={iconTime} alt={constants.GENERAL.ALT_ICON}/>
                <p className="details-vote-voting-observers-counting__last-update-icon-label">{listMembers?.updatedTime !== undefined ? formatTime(listMembers?.updatedTime) : ''}</p>
            </div>
            {showNoActiveVotingObservers && (
                <div className="details-vote-voting-observers-counting__no-active-voting-block">
                    <img className="details-vote-voting-observers-counting__no-active-voting-img" src={imageNoActiveVoting} alt={constants.GENERAL.ALT_ICON}/>
                    <p>{setEmptyTextDetailVote(changeStatusColumn, constants)} <span className="details-vote-voting-observers-counting__no-active-voting-block-link" onClick={() => handleResetIsSelectedTemplateMode('linkAddNewVote')}>{constants.DETAILS_VOTE.VOTING_EMPTY_INFO_LINK}</span></p>
                </div>
                )}
            {hideBlock && (
            <>
            {isShowSendNotify ? (
                <div className="details-vote-voting-observers-counting__pagination-send-notify-block">
                    <button onClick={onHandleSendNotify} className={isLoading ? "details-vote-voting-observers-counting__send-notify-button active" : "details-vote-voting-observers-counting__send-notify-button"}>
                        {isLoading ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.DETAILS_VOTE.VOTING_SEND_NOTIFY_MESSAGE}`}</button>
                    <PaginationBlock
                        sortList={usersForRender}
                        search={usersSearch}
                        searchInput={searchInput}
                        onChoiceClick={onChoiceClick}
                        selectedResultsShow={selectedResultsShow}
                        pageCount={pageCount}
                        showPrevResults={showPrevResults}
                        showNextResults={showNextResults}
                        constants={constants}
                    />
                </div>
            ) : (
                <PaginationBlock
                    sortList={usersForRender}
                    search={usersSearch}
                    searchInput={searchInput}
                    onChoiceClick={onChoiceClick}
                    selectedResultsShow={selectedResultsShow}
                    pageCount={pageCount}
                    showPrevResults={showPrevResults}
                    showNextResults={showNextResults}
                    constants={constants}
                />
            )}
            <div className="details-vote-voting-observers-counting__table">
                {usersForRender?.length !== 0 ? (
                    <>
                        {usersForRender?.sort(function (a, b) {
                            const emailA = a?.email?.toLowerCase(), emailB = b?.email?.toLowerCase()
                            if (emailA < emailB)
                                return -1
                            if (emailA > emailB)
                                return 1
                            return 0
                        }).slice(showResultsFrom, resultsShow).map((el, i) => (
                            <div key={i} className="details-vote-voting-observers-counting__table-row">
                                <p className="details-vote-voting-observers-counting__table-row-username">{el?.last_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}` : el?.last_name} {el?.first_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}` : el?.first_name} {el?.second_name === undefined ? `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}` : el?.second_name}</p>
                                <p className="details-vote-voting-observers-counting__table-row-email">{el?.email}</p>
                                {changeStatusColumn === 'voting' ?
                                    <div className="details-vote-voting-observers-counting__table-row-status">
                                        <div className="details-vote-voting-observers-counting__table-row-status-icon-label">
                                            <img className="details-vote-voting-observers-counting__table-row-status-icon" src={el?.isRegistered ? iconReg : iconNoReg} alt={constants.GENERAL.ALT_ICON}/>
                                            <span className="details-vote-voting-observers-counting__table-row-status-label" style={el?.isRegistered ? {color: '#4ED4A9'} : {color: 'rgba(54, 59, 77, 0.6)'}}>
                                    {el?.isRegistered ? `${constants.DETAILS_VOTE.VOTING_STATUS_REGISTRED}` : `${constants.DETAILS_VOTE.VOTING_STATUS_NO_REGISTRED}`}</span>
                                            <div className="details-vote-voting-observers-counting__table-row-status-mobile" style={el?.isRegistered ? {background: 'rgba(78, 212, 169, 0.15)'} : {background: 'rgba(55, 60, 78, 0.1)'}}>
                                                <li className="details-vote-voting-observers-counting__table-row-status-mobile-label" style={el?.isRegistered ? {color: '#4ED4A9'} : {color: 'rgba(54, 59, 77, 0.6)'}}>
                                                    {el?.isRegistered ? `${constants.DETAILS_VOTE.VOTING_STATUS_REGISTRED}` : `${constants.DETAILS_VOTE.VOTING_STATUS_NO_REGISTRED}`}
                                                </li>
                                            </div>
                                        </div>
                                        <div className="details-vote-voting-observers-counting__table-row-status-icon-label">
                                            <img className="details-vote-voting-observers-counting__table-row-status-icon" src={el?.isVoted ? iconVoted : iconNoVoted} alt={constants.GENERAL.ALT_ICON}/>
                                            <span className="details-vote-voting-observers-counting__table-row-status-label" style={el?.isVoted ? {color: '#4569FF'} : {color: '#FF4970'}}>
                                    {el?.isVoted ? `${constants.DETAILS_VOTE.VOTING_STATUS_VOTED}` : `${constants.DETAILS_VOTE.VOTING_STATUS_NO_VOTED}`}</span>
                                            <div className="details-vote-voting-observers-counting__table-row-status-mobile" style={el?.isVoted ? {background: 'rgba(69, 105, 255, 0.15)'} : {background: 'rgba(255, 73, 112, 0.15)'}}>
                                                <li className="details-vote-voting-observers-counting__table-row-status-mobile-label" style={el?.isVoted ? {color: '#4569FF'} : {color: '#FF4970'}}>
                                                    {el?.isVoted ? `${constants.DETAILS_VOTE.VOTING_STATUS_VOTED}` : `${constants.DETAILS_VOTE.VOTING_STATUS_NO_VOTED}`}
                                                </li>
                                            </div>
                                        </div>
                                    </div> : null}
                                {changeStatusColumn === 'counting' ?
                                    <div className="details-vote-voting-observers-counting__table-row-status">
                                        <div className="details-vote-voting-observers-counting__table-row-status-counting-icon-label">
                                            <img className="details-vote-voting-observers-counting__table-row-status-counting-icon" src={el?.isSigned ? iconReg : iconNoVoted} alt={constants.GENERAL.ALT_ICON}/>
                                            <p className="details-vote-voting-observers-counting__table-row-status-counting-label" style={el?.isSigned ? {color: '#4ED4A9'} : {color: '#FF4970'}}>
                                                {el?.isSigned ? `${constants.DETAILS_VOTE.COUNTING_STATUS_SIGNED}` : `${constants.DETAILS_VOTE.COUNTING_STATUS_NO_SIGNED}`}</p>
                                        </div>
                                    </div> : null}
                                {changeStatusColumn === 'observers' ?
                                    <div className="details-vote-voting-observers-counting__table-row-status">
                                        <p className='details-vote-voting-observers-counting__table-row-status-observers-label'>{el?.phone}</p>
                                    </div> : null}
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        <div className="details-vote-voting-observers-counting__no-users-container">
                            <p className="details-vote-voting-observers-counting__no-users">{constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS}<span className="name-org-input__red-star"> *</span></p>
                        </div>
                    </>
                )}
                {/*<p className="details-vote-voting-observers-counting__show-more">{constants.DETAILS_VOTE.STATISTIC_SHOW_MORE_BTN}</p>*/}
            </div>
            </>
            )}
            {!showNoActiveVotingObservers && <PaginationBlock
                sortList={usersForRender}
                search={usersSearch}
                searchInput={searchInput}
                onChoiceClick={onChoiceClick}
                selectedResultsShow={selectedResultsShow}
                pageCount={pageCount}
                showPrevResults={showPrevResults}
                showNextResults={showNextResults}
                constants={constants}
            />}
        </div>
    )
}
export default DetailsVoteVotingObserversCounting;