import React, { useState } from "react";
import DetailsVotesPageResultGridTable from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable";
import DetailsVotesPageResultGridTable2 from "../DetailsVotesPageResultGridTable/DetailsVotesPageResultGridTable2";

import DetailsVotesPageResultVotesCardQuestionGraphGrid from "../DetailsVotesPageResultVotesCardQuestionGraph/DetailsVotesPageResultVotesCardQuestionGraphGrid";

const DetailsVotesPageResultQuestionGrid = (props) => {
  const { question, constants } = props;

  const [graphResult, setGraphResult] = useState(false);
  const [tableResult, setTableResult] = useState(false);
  const [table2Result, setTable2Result] = useState(true);

  function toggleGraphShow() {
    setGraphResult(true);
    setTableResult(false);
    setTable2Result(false);
  }

  function toggleTableShow() {
    setTableResult(true);
    setGraphResult(false);
    setTable2Result(false);
  }

  function toggleTable2Show() {
    setTable2Result(true);
    setGraphResult(false);
    setTableResult(false);
  }

  return (
    <div className="details-votes-page-result-question-grid" style={{borderBottom: "none"}}>
      <div className="details-votes-page-result-question-grid__title-container">
        <h3 className="details-votes-page-result-question-grid__title">
          {question.title}
        </h3>
        <h5 className="details-votes-page-result-question-grid__rule">
          {constants.DETAILS_VOTE.RESULTS_SELECT_VARIANTS_ANSWER}
        </h5>
      </div>
      <div className="details-votes-page-result-votes-card__switch-table-gistogramma">
        <div
          onClick={toggleGraphShow}
          className={
            graphResult
              ? "switch-table-gistogramma__gistogramma active"
              : "switch-table-gistogramma__gistogramma"
          }
        ></div>
        <div
          onClick={toggleTableShow}
          className={
            tableResult
              ? "switch-table-gistogramma__table active"
              : "switch-table-gistogramma__table"
          }
        ></div>

        <div className="tooltip">
          <div
            onClick={toggleTable2Show}
            className={
              table2Result
                ? "switch-table-gistogramma__table2 active"
                : "switch-table-gistogramma__table2"
            }
          ></div>
        </div>
      </div>
      {tableResult && (
        <div className="details-votes-page-result-question-grid__tables-container">
          {question.answers.map((answer) => (
            <DetailsVotesPageResultGridTable key={answer.id} answer={answer} />
          ))}
        </div>
      )}

      {table2Result && (
        <DetailsVotesPageResultGridTable2
          answers={question.answers}
          question={question}
        />
      )}
      {graphResult && (
        <DetailsVotesPageResultVotesCardQuestionGraphGrid
          answersTemplateGrid={question.answers}
        />
      )}
    </div>
  );
};
export default DetailsVotesPageResultQuestionGrid;
